import React from 'react';

import { connect } from 'react-redux';

import { Container, Row, Col, Image, Carousel, Navbar } from 'react-bootstrap';

// import strings from '../../Localization/Localization';

import '../../css/login_v2.css';
import image from '../../image/nk_logo_nk_nyukom.png';
import image_alt from '../../image/nk_logo_nk_nyukom_alt.png';
import Footer from '../Layout/Footer';

import LoginForm from './LoginForm';

import { withRouter } from "react-router-dom";

const LoginTheme = React.lazy(() => import('../Theme/Themes/login'));

/**
 * Login
 *
 * This component represent a page.
 *
 * This page is used to log in user.
 */
class LoginV2 extends React.Component {

  getLogo() {
    switch (this.props.theme) {
      case "light":
        return image_alt;
      case "dark_colorblind":
      case "dark":
      default:
        return image
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div style={{height: '100%', width: '100%'}}>
        <React.Suspense fallback={<></>}>
          <LoginTheme />
        </React.Suspense>
        <Navbar fixed="top" className="navbar-login-header" bg="my-primary" expand="lg">
          <Container className="d-flex container-header">
          </Container>
        </Navbar>
        <Row className="main-container no-gutters">
          <Col xs={12} md={4} className="left-container">
            <Row className="d-flex justify-content-center no-gutters logo-container">
              <Image className="logo" src={this.getLogo()}/>
            </Row>
            <Row className="d-flex justify-content-center no-gutters greetings-container">
              <h3 className="greetings">Bonjour, ravi de vous revoir !</h3>
            </Row>
            <Row className="d-flex justify-content-center left-container-login">
              <LoginForm onAuthenticationSuccess={this.onAuthenticationSuccess}/>
            </Row>
            {/*<Row className="d-flex justify-content-center no-gutters">
              <div>{">"}Vous n'êtes pas encore client Nyukom ?</div>
            </Row>
            <Row className="d-flex justify-content-center no-gutters">
              <div>Demandez vite votre accès !</div>
            </Row>*/}
          </Col>
          <Col md={8} className="d-none d-md-flex right-container">
            <Carousel>
              <Carousel.Item>
                <a className="container-login-carousel-img" href="https://nyukom.com/cloud-prive/">
                  <img
                    className="login-carousel-img"
                    src="login_page/NK_extranet_login_slideshow_cloud.jpg"
                    alt="Slideshow cloud"
                  />
                </a>
              </Carousel.Item>
               <Carousel.Item>
                <a className="container-login-carousel-img" href="https://nyukom.com/network/">
                  <img
                    className="login-carousel-img"
                    src="login_page/NK_extranet_login_slideshow_network.jpg"
                    alt="Slideshow network"
                  />
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a className="container-login-carousel-img" href="https://nyukom.com/voix/">
                  <img
                    className="login-carousel-img"
                    src="login_page/NK_extranet_login_slideshow_voice.jpg"
                    alt="Slideshow voice"
                  />
                </a>
              </Carousel.Item>
            </Carousel>
          </Col>
          <Footer/>
        </Row>
      </div>
    );
  }

  /**
   * on Authentication success, redirect the user to the homepage of the
   * application
   */
  onAuthenticationSuccess = () => {
    window.location.assign('/homepage')
  }
}

const mapStateToProps = state => ({
  theme: state.persisted.theme.name
})

export default connect(mapStateToProps)(withRouter(LoginV2));
