import strings from '../../Localization/Localization';

const _1_home = 10000;

const _1_partner = 20000;
const _1_partner_2_partner = _1_partner + 1000;
const _1_partner_2_place = _1_partner + 2000;
const _1_partner_2_user = _1_partner + 3000;
const _1_partner_2_user_profil = _1_partner + 4000;

const _1_product = 30000;
const _1_product_2_product = _1_product + 1000;
const _1_product_2_family = _1_product + 2000;
const _1_product_2_sub_family = _1_product + 3000;
const _1_product_2_dependency = _1_product + 4000;
const _1_product_2_product_dependency = _1_product + 5000;
const _1_product_2_phone_system = _1_product + 6000;
const _1_product_2_phone_system_3_phone_package = _1_product_2_phone_system + 100;
const _1_product_2_phone_system_3_price_list = _1_product_2_phone_system + 200;
const _1_product_2_phone_system_3_zone = _1_product_2_phone_system + 300;
const _1_product_2_phone_system_3_zone_group = _1_product_2_phone_system + 400;
const _1_product_2_phone_system_3_prefix = _1_product_2_phone_system + 500;
const _1_product_2_phone_system_3_error_processing = _1_product_2_phone_system + 600;

const _1_order = 40000;
// const _1_order_2_project = _1_order + 1000;
const _1_order_2_quote = _1_order + 1000;
const _1_order_2_order = _1_order + 2000;
const _1_order_2_ticket = _1_order + 3000;
const _1_order_2_commercial_bonus = _1_order + 4000;
const _1_order_2_commercial_bonus_threshold = _1_order + 5000;

const _1_services = 50000;
const _1_cdrs = 60000;

const _1_ticket = 70000;
const _1_ticket_2_technical = _1_ticket + 1000;
// const _1_ticket_2_commercial = _1_ticket + 2000;
const _1_ticket_2_adminitrative = _1_ticket + 3000;
// const _1_ticket_2_project = _1_ticket + 4000;

const _1_accounting = 80000;
const _1_accounting_2_bill = _1_accounting + 1000;
const _1_accounting_2_billing = _1_accounting + 2000;
const _1_accounting_2_account_product = _1_accounting + 3000;
const _1_accounting_2_account_analytic = _1_accounting + 4000;
const _1_accounting_2_account_vat = _1_accounting + 5000;
const _1_accounting_2_accounting_type = _1_accounting + 6000;

const _1_document = 90000;

const _1_website_administration = 1000000;
const _1_website_administration_2_log = _1_website_administration + 1000;
const _1_website_administration_2_partner_type = _1_website_administration + 2000;
const _1_website_administration_2_constructor = _1_website_administration + 3000;


export const menu = [
  {
    id: 'home',
    order: _1_home,
    type: 'link',
    path: '/homepage',
    icon: 'icon-home',
    label: strings.common.mainMenu.home
  },
  {
    id: 'dashboard',
    order: _1_home+0.01,
    type: 'link',
    path: '/dashboard',
    icon: 'icon-home',
    label: strings.common.mainMenu.dashboard
  },

  //for nyukom user
  {
    id: 'partner',
    order: _1_partner,
    type: 'submenu',
    mandatoryRoles: ['ROLE_NYUKOM'],
    icon: 'icon-user-info',
    label: strings.common.mainMenu.partner.global,
    children: [
      {
        id: 'partnerIndex',
        order: _1_partner_2_partner,
        type: 'link',
        path: '/partners/index',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-clients',
        label: strings.common.mainMenu.partner.index,
      },
      {
        id: 'placeIndex',
        order: _1_partner_2_place,
        type: 'link',
        path: '/places/index',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-pin',
        label: strings.common.mainMenu.place.index,
      },
      {
        id: 'usersIndex',
        order: _1_partner_2_user,
        type: 'link',
        path: '/users/index',
        mandatoryRoles: ['ROLE_USER_INDEX'],
        icon: 'icon-users',
        label: strings.common.mainMenu.user.index,
      },
      {
        id: 'profilIndex',
        order: _1_partner_2_user_profil,
        type: 'link',
        path: '/profils/index',
        mandatoryRoles: ['ROLE_ADMIN'],
        icon: 'icon-code',
        label: strings.common.mainMenu.profils.index,
      },
    ]
  },
  //for partner user
  {
    id: 'clientPartner',
    order: _1_partner,
    type: 'submenu',
    mandatoryRoles: ['ROLE_USER'],
    excludedRoles: ['ROLE_NYUKOM'],
    icon: 'icon-user-info',
    label: strings.common.mainMenu.partner.globalClient,
    children: [
      {
        id: 'partnerIndex',
        order: _1_partner_2_partner,
        type: 'link',
        path: '/partners/index',
        mandatoryRoles: ['ROLE_USER'],
        icon: 'icon-clients',
        label: strings.common.mainMenu.partner.indexClient,
      },
      {
        id: 'placeIndex',
        order: _1_partner_2_place,
        type: 'link',
        path: '/places/index',
        mandatoryRoles: ['ROLE_USER'],
        icon: 'icon-pin',
        label: strings.common.mainMenu.place.indexClient,
      },
      {
        id: 'usersIndex',
        order: _1_partner_2_user,
        type: 'link',
        path: '/users/index',
        mandatoryRoles: ['ROLE_USER_INDEX'],
        icon: 'icon-users',
        label: strings.common.mainMenu.user.indexClient,
      },
    ]
  },

  //for nyukom user
  {
    id: 'product',
    order: _1_product,
    type: 'submenu',
    mandatoryRoles: ['ROLE_NYUKOM'],
    icon: 'icon-store',
    label: strings.common.mainMenu.product.global,
    children: [
      {
        id: 'productIndex',
        order: _1_product_2_product,
        type: 'link',
        path: '/products/index',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-nk',
        label: strings.common.mainMenu.product.index,
      },
      {
        id: 'productFamilyIndex',
        order: _1_product_2_family,
        type: 'link',
        path: '/productFamilies/index',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-skip',
        label: strings.common.mainMenu.productFamily.index,
      },
      {
        id: 'productSubFamilyIndex',
        order: _1_product_2_sub_family,
        type: 'link',
        path: '/productSubFamilies/index',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-skip',
        label: strings.common.mainMenu.productSubFamily.index,
      },
      {
        id: 'dependencyIndex',
        order: _1_product_2_dependency,
        type: 'link',
        path: '/dependencies/index',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-skip',
        label: strings.common.mainMenu.dependencies.index,
      },
      {
        id: 'productDependencyIndex',
        order: _1_product_2_product_dependency,
        type: 'link',
        path: '/productDependencies/index',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-skip',
        label: strings.common.mainMenu.productDependencies.index,
      },
      {
        id: 'phoneSystem',
        order: _1_product_2_phone_system,
        type: 'submenu',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-telephone',
        label: strings.common.mainMenu.phoneSystem.global,
        children: [
          {
            id: 'phonePackageIndex',
            order: _1_product_2_phone_system_3_phone_package,
            type: 'link',
            path: '/phonePackage/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'icon-skip',
            label: strings.common.mainMenu.phonePackage.index,
          },
          {
            id: 'phonePriceListIndex',
            order: _1_product_2_phone_system_3_price_list,
            type: 'link',
            path: '/phonePriceList/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'icon-skip',
            label: strings.common.mainMenu.phonePriceList.index,
          },
          {
            id: 'phoneZoneIndex',
            order: _1_product_2_phone_system_3_zone,
            type: 'link',
            path: '/phoneZone/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'icon-skip',
            label: strings.common.mainMenu.phoneZone.index,
          },
          {
            id: 'phoneZoneGroupIndex',
            order: _1_product_2_phone_system_3_zone_group,
            type: 'link',
            path: '/phoneZoneGroup/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'icon-skip',
            label: strings.common.mainMenu.phoneZoneGroup.index,
          },
          {
            id: 'phonePrefixIndex',
            order: _1_product_2_phone_system_3_prefix,
            type: 'icon-skip',
            path: '/phonePrefix/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'file',
            label: strings.common.mainMenu.phonePrefix.index,
          },
          {
            id: 'phoneErrorProcessing',
            order: _1_product_2_phone_system_3_error_processing,
            type: 'link',
            path: '/cdrErrorProcessing',
            mandatoryRoles: ['ROLE_CDR_TECH_ERROR_PROCESSING'],
            icon: 'icon-skip',
            label: strings.common.mainMenu.phoneErrorProcessing.index,
          },
        ]
      },
    ]
  },
  //for partner user
  {
    id: 'clientProduct',
    order: _1_product_2_product,
    type: 'link',
    path: '/products/index',
    mandatoryRoles: ['ROLE_USER'],
    excludedRoles: ['ROLE_NYUKOM'],
    icon: 'icon-store',
    label: strings.common.mainMenu.product.indexClient,
  },

  //for nyukom user
  {
    id: 'order',
    order: _1_order,
    type: 'submenu',
    authRoles: ['ROLE_ORDER', 'ROLE_QUOTE'],
    mandatoryRoles: ['ROLE_NYUKOM'],
    icon: 'icon-deplacement',
    label: strings.common.mainMenu.order.global,
    children: [
      {
        id: 'orderIndex',
        order: _1_order_2_order,
        type: 'link',
        path: '/orders/index',
        mandatoryRoles: 'ROLE_ORDER',
        icon: 'icon-deplacement',
        label: strings.common.mainMenu.order.index,
      },
      {
        id: 'quoteIndex',
        order: _1_order_2_quote,
        type: 'link',
        path: '/quotes/index',
        mandatoryRoles: 'ROLE_QUOTE',
        icon: 'icon-doc',
        label: strings.common.mainMenu.quote.index,
      },
      {
        id: 'commercialTickets',
        order: _1_order_2_ticket,
        type: 'submenu',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-music',
        label: strings.common.mainMenu.commercialTicket.menu,
        children: [
          {
            id: 'commercialTicketIndex',
            order: _1_order_2_ticket + .01,
            type: 'link',
            path: '/tickets/commercial/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'icon-ticket',
            label: strings.common.mainMenu.commercialTicket.index,
          },
          {
            id: 'commercialTicketTypeIndex',
            order: _1_order_2_ticket + .02,
            type: 'link',
            path: '/ticketTypes/commercial/index',
            mandatoryRoles: 'ROLE_COMMERCIAL_TICKET_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.commercialTicket.typeIndex,
          },
          {
            id: 'commercialAppointmentTypeIndex',
            order: _1_order_2_ticket + .03,
            type: 'link',
            path: '/appointmentTypes/commercial/index',
            mandatoryRoles: 'ROLE_COMMERCIAL_TICKET_APPOINTMENT_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.commercialTicket.appointmentTypeIndex,
          },
          {
            id: 'commercialTicketFormIndex',
            order: _1_order_2_ticket + .04,
            type: 'link',
            path: '/ticketForms/commercial/index',
            mandatoryRoles: 'ROLE_COMMERCIAL_TICKET_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.commercialTicket.formIndex,
          },
          {
            id: 'commercialPreFillCommentIndex',
            order: _1_order_2_ticket + .05,
            type: 'link',
            path: '/preFillComment/commercial/index',
            mandatoryRoles: 'ROLE_NYUKOM',
            icon: 'icon-skip',
            label: strings.common.mainMenu.commercialTicket.preFillCommentIndex,
          },
        ]
      },

      {
        id: 'commercialBonusIndex',
        order: _1_order_2_commercial_bonus,
        type: 'link',
        path: '/commercialBonus/index',
        mandatoryRoles: 'ROLE_COMMERCIAL_BONUS',
        icon: 'icon-doc',
        label: strings.common.mainMenu.commercialBonus.index,
      },
      {
        id: 'commercialBonusThresholdIndex',
        order: _1_order_2_commercial_bonus_threshold,
        type: 'link',
        path: '/commercialBonusThreshold/index',
        mandatoryRoles: 'ROLE_COMMERCIAL_BONUS_THRESHOLD',
        icon: 'icon-doc',
        label: strings.common.mainMenu.commercialBonusThreshold.index,
      },
      // {
      //   id: 'project',
      // },
    ]
  },
  //for partner user
  {
    id: 'clientOrder',
    order: _1_order,
    type: 'submenu',
    authRoles: ['ROLE_ORDER', 'ROLE_QUOTE'],
    excludedRoles: ['ROLE_NYUKOM'],
    icon: 'icon-deplacement',
    label: strings.common.mainMenu.order.global,
    children: [
      {
        id: 'orderIndex',
        order: _1_order_2_order,
        type: 'link',
        path: '/orders/index',
        mandatoryRoles: 'ROLE_ORDER',
        icon: 'icon-deplacement',
        label: strings.common.mainMenu.order.indexClient,
      },
      {
        id: 'quoteIndex',
        order: _1_order_2_quote,
        type: 'link',
        path: '/quotes/index',
        mandatoryRoles: 'ROLE_QUOTE',
        icon: 'icon-doc',
        label: strings.common.mainMenu.quote.indexClient,
      },
      {
        id: 'commercialTickets',
        order: _1_order_2_ticket,
        type: 'link',
        path: '/tickets/commercial/index',
        mandatoryRoles: ['ROLE_USER'],
        icon: 'icon-ticket',
        label: strings.common.mainMenu.commercialTicket.menu
      },
      // {
      //   id: 'project',
      // },
    ]
  },
  //for nyukom user
  {
    id: 'serviceIndex',
    order: _1_services,
    type: 'link',
    path: '/services/index',
    mandatoryRoles: ['ROLE_NYUKOM'],
    icon: 'icon-sante',
    label: strings.common.mainMenu.service.index,
  },
  //for partner user
  {
    id: 'clientServiceIndex',
    order: _1_services,
    type: 'link',
    path: '/services/index',
    mandatoryRoles: ['ROLE_USER'],
    excludedRoles: ['ROLE_NYUKOM'],
    icon: 'icon-sante',
    label: strings.common.mainMenu.service.indexClient,
  },
  //for nyukom user
  {
    id: 'cdrIndex',
    order: _1_cdrs,
    type: 'link',
    path: '/phoneCDR/index',
    mandatoryRoles: ['ROLE_CDR'],
    icon: 'icon-telephone',
    label: strings.common.mainMenu.cdr.index,
  },

  //for nyukom user
  {
    id: 'support',
    order: _1_ticket,
    type: 'submenu',
    mandatoryRoles: ['ROLE_NYUKOM'],
    icon: 'icon-support-line',
    label: strings.common.mainMenu.ticket.global,
    children: [
      {
        id: 'technicalTickets',
        order: _1_ticket_2_technical,
        type: 'submenu',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-support',
        label: strings.common.mainMenu.technicalTicket.menu,
        children: [
          {
            id: 'technicalTicketIndex',
            order: _1_ticket_2_technical + .01,
            type: 'link',
            path: '/tickets/technical/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'icon-ticket',
            label: strings.common.mainMenu.technicalTicket.index,
          },
          {
            id: 'technicalTicketTypeIndex',
            order: _1_ticket_2_technical + .02,
            type: 'link',
            path: '/ticketTypes/technical/index',
            mandatoryRoles: 'ROLE_TECHNICAL_TICKET_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.technicalTicket.typeIndex,
          },
          {
            id: 'technicalAppointmentTypeIndex',
            order: _1_ticket_2_technical + .03,
            type: 'link',
            path: '/appointmentTypes/technical/index',
            mandatoryRoles: 'ROLE_TECHNICAL_TICKET_APPOINTMENT_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.technicalTicket.appointmentTypeIndex,
          },
          {
            id: 'technicalTicketFormIndex',
            order: _1_ticket_2_technical + .04,
            type: 'link',
            path: '/ticketForms/technical/index',
            mandatoryRoles: 'ROLE_TECHNICAL_TICKET_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.technicalTicket.formIndex,
          },
          {
            id: 'technicalPreFillCommentIndex',
            order: _1_ticket_2_technical + .05,
            type: 'link',
            path: '/preFillComment/technical/index',
            mandatoryRoles: 'ROLE_NYUKOM',
            icon: 'icon-skip',
            label: strings.common.mainMenu.technicalTicket.preFillCommentIndex,
          },
          {
            id: 'ticketTimeZone',
            order: _1_ticket_2_technical + .06,
            type: 'link',
            path: '/ticketTimeZone/index',
            mandatoryRoles: 'ROLE_ADMIN',
            icon: 'icon-skip',
            label: strings.common.mainMenu.technicalTicket.ticketTimeZone,
          },
        ]
      },
      {
        id: 'administrativeTickets',
        order: _1_ticket_2_adminitrative,
        type: 'submenu',
        mandatoryRoles: ['ROLE_NYUKOM'],
        icon: 'icon-doc-on',
        label: strings.common.mainMenu.administrativeTicket.menu,
        children: [
          {
            id: 'administrativeTicketIndex',
            order: _1_ticket_2_adminitrative + .01,
            type: 'link',
            path: '/tickets/administrative/index',
            mandatoryRoles: ['ROLE_NYUKOM'],
            icon: 'icon-ticket',
            label: strings.common.mainMenu.administrativeTicket.index,
          },
          {
            id: 'administrativeTicketTypeIndex',
            order: _1_ticket_2_adminitrative + .02,
            type: 'link',
            path: '/ticketTypes/administrative/index',
            mandatoryRoles: 'ROLE_ADMINISTRATIVE_TICKET_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.administrativeTicket.typeIndex,
          },
          {
            id: 'administrativeAppointmentTypeIndex',
            order: _1_ticket_2_adminitrative + .03,
            type: 'link',
            path: '/appointmentTypes/administrative/index',
            mandatoryRoles: 'ROLE_ADMINISTRATIVE_TICKET_APPOINTMENT_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.administrativeTicket.appointmentTypeIndex,
          },
          {
            id: 'administrativeTicketFormIndex',
            order: _1_ticket_2_adminitrative + .04,
            type: 'link',
            path: '/ticketForms/administrative/index',
            mandatoryRoles: 'ROLE_ADMINISTRATIVE_TICKET_TYPE',
            icon: 'icon-skip',
            label: strings.common.mainMenu.administrativeTicket.formIndex,
          },
          {
            id: 'administrativePreFillCommentIndex',
            order: _1_ticket_2_adminitrative + .05,
            type: 'link',
            path: '/preFillComment/administrative/index',
            mandatoryRoles: 'ROLE_NYUKOM',
            icon: 'icon-skip',
            label: strings.common.mainMenu.administrativeTicket.preFillCommentIndex,
          },
        ]
      },
      // {
      //   id: 'project',
      // },
    ]
  },
  //for partner user
  {
    id: 'clientSupport',
    order: _1_ticket,
    type: 'submenu',
    mandatoryRoles: ['ROLE_USER'],
    excludedRoles: ['ROLE_NYUKOM'],
    icon: 'icon-support-line',
    label: strings.common.mainMenu.ticket.globalClient,
    children: [
      {
        id: 'technicalTickets',
        order: _1_ticket_2_technical,
        type: 'link',
        path: '/tickets/technical/index',
        mandatoryRoles: ['ROLE_USER'],
        icon: 'icon-support',
        label: strings.common.mainMenu.technicalTicket.menu
      },
      {
        id: 'administrativeTickets',
        order: _1_ticket_2_adminitrative,
        type: 'link',
        path: '/tickets/administrative/index',
        mandatoryRoles: ['ROLE_USER'],
        icon: 'icon-doc-on',
        label: strings.common.mainMenu.administrativeTicket.menu
      },
    ]
  },

  //for nyukom user
  {
    id: 'accounting',
    order: _1_accounting,
    type: 'submenu',
    authRoles: ['ROLE_ACCOUNTING_BILL', 'ROLE_ACCOUNTING_ACCOUNT'],
    mandatoryRoles: ['ROLE_NYUKOM'],
    icon: 'icon-doc',
    label: strings.common.mainMenu.accounting.global,
    children: [
      {
        id: 'billsIndex',
        order: _1_accounting_2_bill,
        type: 'link',
        path: '/bills/index',
        mandatoryRoles: 'ROLE_ACCOUNTING_BILL',
        icon: 'icon-doc',
        label: strings.common.mainMenu.bill.index,
      },
      {
        id: 'billings',
        order: _1_accounting_2_billing,
        type: 'link',
        path: '/billings/index',
        mandatoryRoles: 'ROLE_ACCOUNTING_ACCOUNT',
        icon: 'icon-skip',
        label: strings.common.mainMenu.billing.index,
      },
      {
        id: 'accountProductIndex',
        order: _1_accounting_2_account_product,
        type: 'link',
        path: '/accountProducts/index',
        mandatoryRoles: 'ROLE_ACCOUNTING_ACCOUNT',
        icon: 'icon-skip',
        label: strings.common.mainMenu.accountProduct.index,
      },
      {
        id: 'accountAnalyticIndex',
        order: _1_accounting_2_account_analytic,
        type: 'link',
        path: '/accountAnalytics/index',
        mandatoryRoles: 'ROLE_ACCOUNTING_ACCOUNT',
        icon: 'icon-skip',
        label: strings.common.mainMenu.accountAnalytic.index,
      },
      {
        id: 'accountVatIndex',
        order: _1_accounting_2_account_vat,
        type: 'link',
        path: '/accountVats/index',
        mandatoryRoles: 'ROLE_ACCOUNTING_ACCOUNT',
        icon: 'icon-skip',
        label: strings.common.mainMenu.accountVat.index,
      },
      {
        id: 'accountingTypeIndex',
        order: _1_accounting_2_accounting_type,
        type: 'link',
        path: '/accountingTypes/index',
        mandatoryRoles: 'ROLE_ACCOUNTING_ACCOUNT',
        icon: 'icon-skip',
        label: strings.common.mainMenu.accountingType.index,
      },
    ]
  },
  //for partner user
  {
    id: 'clientAccounting',
    order: _1_accounting_2_bill,
    type: 'link',
    path: '/bills/index',
    mandatoryRoles: ['ROLE_ACCOUNTING_BILL'],
    excludedRoles: ['ROLE_NYUKOM'],
    icon: 'icon-doc',
    label: strings.common.mainMenu.bill.indexClient,
  },
  //for nyukom user
  {
    id: 'documentIndex',
    order: _1_document,
    type: 'link',
    path: '/documents/index',
    mandatoryRoles: ['ROLE_NYUKOM'],
    icon: 'icon-docs',
    label: strings.common.mainMenu.document.index,
  },
  //for partner user
  // {
  //   id: 'clientDocumentIndex',
  //   order: _1_document,
  //   type: 'link',
  //   path: '/documents/index',
  //   mandatoryRoles: ['ROLE_USER'],
  //   excludedRoles: ['ROLE_NYUKOM'],
  //   icon: 'icon-docs',
  //   label: strings.common.mainMenu.document.indexClient,
  // },

  //for nyukom user
  {
    id: 'websiteAdministration',
    order: _1_website_administration,
    type: 'submenu',
    mandatoryRoles: 'ROLE_ADMIN',
    icon: 'icon-code',
    label: strings.common.mainMenu.websiteAdministration.global,
    children: [
      {
        id: 'websiteAdministrationLogs',
        order: _1_website_administration_2_log,
        type: 'link',
        path: '/logs/index',
        mandatoryRoles: 'ROLE_ADMIN',
        icon: 'icon-code',
        label: strings.common.mainMenu.logIndex,
      },
      {
        id: 'accountIndex',
        order: _1_website_administration_2_partner_type,
        type: 'link',
        path: '/partnerTypes/index',
        mandatoryRoles: 'ROLE_ADMIN',
        icon: 'icon-code',
        label: strings.common.mainMenu.partnerType.index,
      },
      {
        id: 'constructorIndex',
        order: _1_website_administration_2_constructor,
        type: 'link',
        path: '/constructors/index',
        mandatoryRoles: 'ROLE_ADMIN',
        icon: 'icon-code',
        label: strings.common.mainMenu.constructor.index,
      },
    ]
  },
]
