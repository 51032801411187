import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  statistic as baseStatistic,
  request
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of bills based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('bills', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single bill based on his ID
 *
 * @param id the of the bill requested
 */
export function getFromId(id) {
  return baseGetFromId('bills', id);
}

/**
 * Get a single bill based on his ID
 *
 * @param id the of the bill requested
 */
export function getBillLinesFromId(id) {
  return baseGetFromId('bills', id, 'bill_lines');
}

/**
 * Get a single bill based on his ID
 *
 * @param id the of the bill requested
 */
export function getDistributionFromId(id) {
  return baseGetFromId('bills', id, 'distribution');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single bill based on his ID
 *
 * @param id the of the bill requested
 * @param data the new data of the bill
 */
export function editFromId(id, data) {
  return baseEditFromId('bills', id, data);
}

/******************************************************************************
 * STATISTIC FUNCTION                                                         *
 ******************************************************************************/

export function statistic(stat, attribute, search) {
  return baseStatistic('bills', stat, attribute, search);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new bill
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return request('/bills', 'POST', data);
}

/******************************************************************************
 * EXPORT FUNCTION                                                            *
 ******************************************************************************/

/**
 * export all bills depending on search
 *
 * @param data the new data of the partner
 */
export function exportData(search) {
  return request('/bills/export', 'GET', search);
}
