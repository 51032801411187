const product = {
  tab: {
    main: "Produit",
    edit: "Editer",
    productPrice: "Prix",
    productDependency: "Dépendence",
    configuration: "Configuration",
    configurationEdit: "Editer",
    contract: "Contrat",
    datasheet: "Données techniques",
    phonePackage: "Forfait Téléphonique",
    specificConditionsOfSale: "Conditions particulières de vente"
  },
  new: {
    title: "Nouveau produit",
    steps: {
      generalData: "Informations Générales",
      configuration: "Configuration",
      price: "Prix",
      contract: "Contrat",
      dependency: "Définition accesoires",
      specificConditionsOfSale: "Conditions particulières de vente"
    }
  },
  search: {
    productFamily: "Famille",
    productSubFamily: "Sous famille",
    name: "Nom",
    code: "Code",
    description: "Description",
    price: "Prix",
    priceType: "Type de prix",
    buyingPrice: "Prix d'achat",
    buyingCompany: "Fournisseur",
    buyingReference: "Référence d'achat",
    createdAtMin: "Date de création <",
    createdAtMax: "Date de création >",
    updatedAtMin: "Date de MàJ <",
    updatedAtMax: "Date de MàJ >",
    constructor: "Constructeur"
  },
  cartItem: {
    periodicity: {
      monthly: "mois",
      fortnightly: "bimestre",
      quarterly: "trimestre",
      halfYearly: "semestre",
      yearly: "an"
    }
  }
};

export default product;
