import React from 'react';

import SubRouter from '../../Routing/SubRouter';

// import Login from '../../Component/Login/Login';
import LoginV2 from '../../Component/Login/LoginV2';
import Logout from '../../Component/Login/Logout';
import Maintenance from '../../Component/Layout/Maintenance';
import Homepage from '../../Component/Layout/Homepage';
import Error from '../../Component/Layout/Error';
import CartSummary from '../../Component/Cart/Summary';
import BillCreator from '../../Component/BillCreation/BillCreator';
import CreditNoteCreator from '../../Component/BillCreation/CreditNoteCreator';
import CdrErrorProcessing from '../../Component/CdrProcessing/ErrorProcessing';

import Dashboard from '../../Component/Dashboard/Dashboard';

import MyEntityTabs from '../../Component/Configurator/MyEntityTabs';

// import Tutorial from '../../Component/Tutorial/Tutorial';

// import TestBootstrap from '../../Component/TestBootstrap';
// import AlphalinkAPITest from '../../Component/AlphalinkAPITest';
//DEBUG
// import ColumnEqualizerTest from '../../Component/_Debug/ColumnEqualizerTest';
// import CopyPasteTest from '../../Component/_Debug/CopyPasteTest';
// import ChartTest from '../../Component/_Debug/ChartTest';
// import ModularPageTest from '../../Component/_Debug/ModularPageTest';

import { conf } from '../Configuration/main';

import { conf as users } from '../Configuration/users';
import { conf as profils } from '../Configuration/profils';

import { conf as orders } from '../Configuration/orders';
import { conf as products } from '../Configuration/products';
import { conf as productFamilies } from '../Configuration/productFamilies';
import { conf as productSubFamilies } from '../Configuration/productSubFamilies';
import { conf as productDependencies } from '../Configuration/productDependencies';
import { conf as dependencies } from '../Configuration/dependencies';
import { conf as constructors } from '../Configuration/constructors';
import { conf as carts } from '../Configuration/carts';
import { conf as quotes } from '../Configuration/quotes';
import { conf as services } from '../Configuration/services';

import { conf as accountProducts } from '../Configuration/accountProducts';
import { conf as accountAnalytics } from '../Configuration/accountAnalytics';
import { conf as accountVats } from '../Configuration/accountVats';
import { conf as accountingTypes } from '../Configuration/accountingTypes';
import { conf as bills } from '../Configuration/bills';

import { conf as billings } from '../Configuration/billings';

import { conf as partners } from '../Configuration/partners';
import { conf as places } from '../Configuration/places';
import { conf as partnerTypes } from '../Configuration/partnerTypes';

import { conf as technicalTicketTypes } from '../Configuration/technicalTicketTypes';
import { conf as commercialTicketTypes } from '../Configuration/commercialTicketTypes';
import { conf as administrativeTicketTypes } from '../Configuration/administrativeTicketTypes';
import { conf as technicalTicket } from '../Configuration/technicalTicket';
import { conf as commercialTicket } from '../Configuration/commercialTicket';
import { conf as administrativeTicket } from '../Configuration/administrativeTicket';
import { conf as technicalTicketForm } from '../Configuration/technicalTicketForm';
import { conf as commercialTicketForm } from '../Configuration/commercialTicketForm';
import { conf as administrativeTicketForm } from '../Configuration/administrativeTicketForm';
import { conf as technicalAppointmentTypes } from '../Configuration/technicalAppointmentTypes';
import { conf as commercialAppointmentTypes } from '../Configuration/commercialAppointmentTypes';
import { conf as administrativeAppointmentTypes } from '../Configuration/administrativeAppointmentTypes';
import { conf as technicalPreFillComment } from '../Configuration/technicalPreFillComment';
import { conf as commercialPreFillComment } from '../Configuration/commercialPreFillComment';
import { conf as administrativePreFillComment } from '../Configuration/administrativePreFillComment';
import { conf as ticketTimeZone } from '../Configuration/ticketTimeZones';

import { conf as phoneCDR } from '../Configuration/phoneCDR';
import { conf as phonePackage } from '../Configuration/phonePackage';
import { conf as phonePriceList } from '../Configuration/phonePriceList';
import { conf as phonePrefix } from '../Configuration/phonePrefix';
import { conf as phoneZoneGroup } from '../Configuration/phoneZoneGroup';
import { conf as phoneZone } from '../Configuration/phoneZone';

import { conf as commercialBonus } from '../Configuration/commercialBonus';
import { conf as commercialBonusThreshold } from '../Configuration/commercialBonusThreshold';

import { conf as logs } from '../Configuration/logs';
import { conf as parameters } from '../Configuration/parameters';

import { conf as documents } from '../Configuration/documents';

import { main as dashboardConf } from '../Dashboard/main';

import { routes as processRoutes } from './process';
// import { routes as usersRoutes } from './users';
import { routes as notificationsRoutes } from './notifications';

import { batchRouting } from './base';

const batchedRoutes = batchRouting({
  users: users,
  profils: profils,

  products: products,
  productFamilies: productFamilies,
  productSubFamilies: productSubFamilies,
  productDependencies: productDependencies,
  dependencies: dependencies,
  constructors: constructors,
  carts: carts,
  orders: orders,
  quotes: quotes,
  services: services,

  accountProducts: accountProducts,
  accountAnalytics: accountAnalytics,
  accountVats: accountVats,
  accountingTypes: accountingTypes,
  bills: bills,

  billings: billings,

  partners: partners,
  places: places,
  partnerTypes: partnerTypes,

  technicalTicketTypes: technicalTicketTypes,
  commercialTicketTypes: commercialTicketTypes,
  administrativeTicketTypes: administrativeTicketTypes,
  technicalTicket: technicalTicket,
  commercialTicket: commercialTicket,
  administrativeTicket: administrativeTicket,
  technicalTicketForm: technicalTicketForm,
  commercialTicketForm: commercialTicketForm,
  administrativeTicketForm: administrativeTicketForm,
  technicalAppointmentTypes: technicalAppointmentTypes,
  commercialAppointmentTypes: commercialAppointmentTypes,
  administrativeAppointmentTypes: administrativeAppointmentTypes,
  technicalPreFillComment: technicalPreFillComment,
  commercialPreFillComment: commercialPreFillComment,
  administrativePreFillComment: administrativePreFillComment,
  ticketTimeZone: ticketTimeZone,

  phoneCDR: phoneCDR,
  phonePackage: phonePackage,
  phonePriceList: phonePriceList,
  phonePrefix: phonePrefix,
  phoneZoneGroup: phoneZoneGroup,
  phoneZone: phoneZone,

  commercialBonus: commercialBonus,
  commercialBonusThreshold: commercialBonusThreshold,

  documents: documents,

  logs: logs,
  parameters: parameters
});

export let
  usersRoutes = batchedRoutes.users,
  profilsRoutes = batchedRoutes.profils,

  productsRoute = batchedRoutes.products,
  productFamiliesRoute = batchedRoutes.productFamilies,
  productSubFamiliesRoute = batchedRoutes.productSubFamilies,
  productDependenciesRoute = batchedRoutes.productDependencies,
  dependenciesRoute = batchedRoutes.dependencies,
  constructorsRoute = batchedRoutes.constructors,
  cartsRoute = batchedRoutes.carts,
  ordersRoute = batchedRoutes.orders,
  quotesRoute = batchedRoutes.quotes,
  servicesRoute = batchedRoutes.services,

  accountProductsRoute = batchedRoutes.accountProducts,
  accountAnalyticsRoute = batchedRoutes.accountAnalytics,
  accountVatsRoute = batchedRoutes.accountVats,
  accountingTypesRoute = batchedRoutes.accountingTypes,
  billsRoute = batchedRoutes.bills,

  billingsRoute = batchedRoutes.billings,

  partnersRoute = batchedRoutes.partners,
  placesRoute = batchedRoutes.places,
  partnerTypesRoute = batchedRoutes.partnerTypes,

  technicalTicketTypesRoute = batchedRoutes.technicalTicketTypes,
  commercialTicketTypesRoute = batchedRoutes.commercialTicketTypes,
  administrativeTicketTypesRoute = batchedRoutes.administrativeTicketTypes,
  technicalTicketRoute = batchedRoutes.technicalTicket,
  commercialTicketRoute = batchedRoutes.commercialTicket,
  administrativeTicketRoute = batchedRoutes.administrativeTicket,
  technicalTicketFormRoute = batchedRoutes.technicalTicketForm,
  commercialTicketFormRoute = batchedRoutes.commercialTicketForm,
  administrativeTicketFormRoute = batchedRoutes.administrativeTicketForm,
  technicalAppointmentTypesRoute = batchedRoutes.technicalAppointmentTypes,
  commercialAppointmentTypesRoute = batchedRoutes.commercialAppointmentTypes,
  administrativeAppointmentTypesRoute = batchedRoutes.administrativeAppointmentTypes,
  technicalPreFillCommentRoute = batchedRoutes.technicalPreFillComment,
  commercialPreFillCommentRoute = batchedRoutes.commercialPreFillComment,
  administrativePreFillCommentRoute = batchedRoutes.administrativePreFillComment,
  ticketTimeZoneRoute = batchedRoutes.ticketTimeZone,

  phoneCDRRoute = batchedRoutes.phoneCDR,
  phonePackageRoute = batchedRoutes.phonePackage,
  phonePriceListRoute = batchedRoutes.phonePriceList,
  phonePrefixRoute = batchedRoutes.phonePrefix,
  phoneZoneGroupRoute = batchedRoutes.phoneZoneGroup,
  phoneZoneRoute = batchedRoutes.phoneZone,

  commercialBonusRoute = batchedRoutes.commercialBonus,
  commercialBonusThresholdRoute = batchedRoutes.commercialBonusThreshold,

  documentsRoute = batchedRoutes.documents,

  logsRoute = batchedRoutes.logs,
  parametersRoute = batchedRoutes.parameters

export const routes = Object.assign(batchedRoutes, {
  maintenance: {
    path: '/maintenance',
    exact: true,
    createPath: () => '/maintenance',
    type: 'component',
    getComponent: () => Maintenance,
  },
  login: {
    path: '/login',
    exact: true,
    createPath: () => '/login',
    type: 'component',
    // getComponent: () => Login,
    getComponent: () => LoginV2
  },
  logout: {
    path: '/logout',
    exact: true,
    createPath: () => '/logout',
    type: 'component',
    getComponent: () => Logout,
  },
  homepage: {
    path: '/homepage',
    exact: true,
    createPath: () => '/homepage',
    type: 'component',
    getComponent: () => Homepage,
  },
  dashboard: {
    path: '/dashboard',
    exact: true,
    createPath: () => '/dashboard',
    type: 'render',
    getRender: (props) => <Dashboard {...props} conf={dashboardConf}/>,
  },
  process: {
    path: '/process',
    exact: false,
    createPath: () => '/process',
    type: 'render',
    getRender: (props) => <SubRouter {...props} conf={processRoutes}/>,
  },
  cartSummary:{
    path: '/cartSummary',
    exact: true,
    createPath: () => '/cartSummary',
    type: 'component',
    getComponent: () => CartSummary
  },
  billCreator:{
    path: '/billCreator/:clientId',
    exact: true,
    createPath: (clientId) => `/billCreator/${clientId}`,
    type: 'component',
    getComponent: () => BillCreator
  },
  creditNoteCreator:{
    path: '/creditNoteCreator/:clientId',
    exact: true,
    createPath: (clientId) => `/creditNoteCreator/${clientId}`,
    type: 'component',
    getComponent: () => CreditNoteCreator
  },
  cdrErrorProcessing: {
    path: '/cdrErrorProcessing',
    exact: true,
    createPath: (clientId) => `/cdrErrorProcessing`,
    type: 'component',
    getComponent: () => CdrErrorProcessing
  },
  profil: {
    path: '/profil',
    exact: false,
    createPath: () => '/profil',
    type: 'render',
    getRender: (props) => <MyEntityTabs {...props} conf={conf.profil}/>,
  },
  notifications: {
    path: '/notifications',
    exact: false,
    createPath: () => '/notifications',
    type: 'render',
    getRender: (props) => <SubRouter {...props} conf={notificationsRoutes}/>,
  },
  // tutorials: {
  //   path: '/tutorials',
  //   exact: true,
  //   createPath: () => '/tutorials',
  //   type: 'component',
  //   getComponent: () => Tutorial
  // },
  // test_bootstrap: {
  //   path: '/test_bootstrap',
  //   exact: true,
  //   createPath: () => '/test_bootstrap',
  //   type: 'component',
  //   getComponent: () => TestBootstrap,
  // },
  // test_alphalink: {
  //   path: '/test_alphalink',
  //   exact: true,
  //   createPath: () => '/test_alphalink',
  //   type: 'component',
  //   getComponent: () => AlphalinkAPITest,
  // },
  // _debug_column_equalizer: {
  //   path: '/_debug_column_equalizer',
  //   exact: true,
  //   createPath: () => '/_debug_column_equalizer',
  //   type: 'component',
  //   getComponent: () => ColumnEqualizerTest,
  // },
  // _debug_copy_paste: {
  //   path: '/_debug_copy_paste',
  //   exact: true,
  //   createPath: () => '/_debug_copy_paste',
  //   type: 'component',
  //   getComponent: () => CopyPasteTest,
  // },
  // _debug_chart: {
  //   path: '/_debug_chart',
  //   exact: true,
  //   createPath: () => '/_debug_chart',
  //   type: 'component',
  //   getComponent: () => ChartTest,
  // },
  // _debug_modular_page: {
  //   path: '/_debug_modular_page',
  //   exact: true,
  //   createPath: () => '/_debug_modular_page',
  //   type: 'component',
  //   getComponent: () => ModularPageTest,
  // },
  error: {
    path: '/*',
    exact: false,
    createPath: () => '/',
    type: 'component',
    getComponent: () => Error,
  },
})

export const route = {
  prefix: '',
  routes: routes
}
