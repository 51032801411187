const _document = {
  index: {
    line: {}
  },
  tab: {
    main: "Document",
  },
  new: {
    title: "Nouveau Document"
  },
  search: {
    name: "Nom",
  }
};

export default _document;
