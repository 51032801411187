import React from 'react';

import strings from '../../Localization/Localization';

import MainLayout from '../Layout/MainLayout';
import SubFooter from '../Layout/SubFooter';

import { connect } from 'react-redux';
import {
  addAmendmentAction
} from '../../Store/Action/cart';
import { addBreadcrumbAction } from '../../Store/Action/breadcrumb';

import { withRouter } from "react-router-dom";

import { withModalHandler } from '../../HOC/ModalHandler';

import { Container, Form, Row, Col, Button, Card } from 'react-bootstrap';

import VerticalCollapse from '../Animation/VerticalCollapse/VerticalCollapse';
import VerticalCollapsed from '../Animation/VerticalCollapse/VerticalCollapsed';

import Loading from '../Layout/Loading';

import MyForm from '../Form/MyForm';
import ItemIndex from '../ItemIndex/ItemIndex';
import ItemIndexCard from '../ItemIndex/Item/ItemIndexCard';

import { conf as productsConf } from '../../MetaData/Configuration/products';

import { services as routes } from '../../MetaData/Route/routes';

import {
  getNewAmendmentFromId as loadItem,
  editNewAmendmentFromId,
  editNewAmendmentServicePriceFromId
} from '../../API/Services';
import { index as loadReplacementChoices } from '../../API/Products';

import {
  quantityForm,
  placeForm,
  priceForm,
  resultForm
} from '../../MetaData/Form/Amendment/amendment';

import FormFieldPrice from '../Form/Field/FormFieldPrice';

/**
 * ServiceAmendment
 */
class ServiceAmendment extends React.Component {

  constructor(props) {
    super(props);

    this.loadItem = this.props.modalHandler.addVerificationWithCallback(loadItem, this.postLoadItem, this.postLoadItemFailure);
    this.loadReplacementChoices = this.props.modalHandler.addVerificationWithCallback(loadReplacementChoices, this.postLoadReplacementChoices, this.postLoadReplacementChoicesFailure);

    this.editNewAmendmentFromId = this.props.modalHandler.addVerificationWithCallback(editNewAmendmentFromId, this.postEditNewAmendment, this.postEditNewAmendmentFailure);
    this.editNewAmendmentServicePriceFromId = this.props.modalHandler.addVerificationWithCallback(editNewAmendmentServicePriceFromId, this.postEditNewAmendmentServicePrice, this.postEditNewAmendmentServicePriceFailure);

    this.quantityFormRef = React.createRef();

    this.state = {
      item: null,

      amendmentQuantity: false,
      amendmentProduct: false,
      amendmentPlace: false,
      amendmentPriceContract: false,
      amendmentPriceService: false,

      amendmentQuantityFormValues: {},

      displayReplacementChoices:false,
      replacementChoices: null,
      replacementSelected: null,

      amendmentPlaceFormValues: {},

      amendmentPriceContractFormValues: {},

      amendmentPriceServiceFormValues: {},

      amendmentFormValues: {},

      loading: true,
      error: null
    }

    if(props.location.state && props.location.state.types) {
      let parameters = props.location.state;
      if(parameters.types.includes('quantity')) {
        this.state.amendmentQuantity = true;
        this.state.amendmentQuantityFormValues.quantity = parameters.quantity;
      }
      if(parameters.types.includes('product')) {
        this.state.amendmentProduct = true;
        this.state.replacementSelected = parameters.product.id;
        this.state.replacementChoices = [parameters.product];
      }
      if(parameters.types.includes('place')) {
        this.state.amendmentPlace = true;
        this.state.amendmentPlaceFormValues.place = parameters.place;
      }
      if(parameters.types.includes('price')) {
        this.state.amendmentPriceContract = true;
        this.state.amendmentPriceContractFormValues.price = parameters.unitPrice;
      }
    }
  }

  componentDidMount() {
    this.loadItem(this.props.match.params.id);
    this.props.dispatch(addBreadcrumbAction(window.location.pathname, strings.amendment.breadcrumb));
  }

  postLoadItem = (data) => {
    this.setState({
      item: data.item,
      loading: false
    })
  }

  postLoadItemFailure = (error) => {
    this.setState({
      error: error.message,
      loading: false
    })
  }

  postLoadReplacementChoices = (data) => {
    this.setState({
      loading: false,
      replacementChoices: data.items
    })
  }

  postLoadReplacementChoicesFailure = () => {
    this.setState({
      loading: false
    })
  }

  postEditNewAmendment = (data) => {
    this.props.history.push(routes.routes.show.createPath(this.props.match.params.id));
  }

  postEditNewAmendmentFailure = (error) => {
    this.setState({
      loading: false,
    })
  }

  postEditNewAmendmentServicePrice = (data) => {
    this.props.history.push(routes.routes.show.createPath(this.props.match.params.id));
  }

  postEditNewAmendmentServicePriceFailure = (error) => {
    this.setState({
      loading: false,
    })
  }


  onChangeQuantityFormValues = (field, value) => {
    let amendmentQuantityFormValues = Object.assign({}, this.state.amendmentQuantityFormValues);
    if(field === 'newQuantity') {
      amendmentQuantityFormValues['newQuantity'] = value;
      amendmentQuantityFormValues['quantity'] = (+value - this.state.item['quantity']).toString();
    }
    else if(field === 'quantity') {
      amendmentQuantityFormValues['quantity'] = value;
      amendmentQuantityFormValues['newQuantity'] = (+value + this.state.item['quantity']).toString();
    }
    this.setState({
      amendmentQuantityFormValues: amendmentQuantityFormValues
    }, () => {
      if(this.quantityFormRef.current) {
        this.quantityFormRef.current.validateAll();
      }
    });
  }

  onChangePlaceFormValues = (field, value) => {
    let amendmentPlaceFormValues = Object.assign({}, this.state.amendmentPlaceFormValues);
    amendmentPlaceFormValues[field] = value;
    this.setState({
      amendmentPlaceFormValues: amendmentPlaceFormValues
    });
  }

  onChangePriceContractFormValues = (field, value) => {
    let amendmentPriceContractFormValues = Object.assign({}, this.state.amendmentPriceContractFormValues);
    amendmentPriceContractFormValues[field] = value;
    this.setState({
      amendmentPriceContractFormValues: amendmentPriceContractFormValues
    });
  }

  onChangePriceServiceFormValues = (field, value) => {
    let amendmentPriceServiceFormValues = Object.assign({}, this.state.amendmentPriceServiceFormValues);
    amendmentPriceServiceFormValues[field] = value;
    this.setState({
      amendmentPriceServiceFormValues: amendmentPriceServiceFormValues
    });
  }

  onChangeFormValues = (field, value) => {
    let amendmentFormValues = Object.assign({}, this.state.amendmentFormValues);
    amendmentFormValues[field] = value;
    this.setState({
      amendmentFormValues: amendmentFormValues
    });
  }

  onClickReplacement = (itemId) => {
    this.setState({
      replacementSelected: itemId
    });
  }

  addAmendmentToCart = () => {
    let amendment = {
      types: []
    };
    let quantity = 0;
    let productId = null;

    if(this.state.item["amendment_quantity"] === true && this.state.amendmentQuantity && this.state.amendmentQuantityFormValues && this.state.amendmentQuantityFormValues.quantity && this.state.amendmentQuantityFormValues.quantity > 0) {
      quantity = this.state.amendmentQuantityFormValues.quantity;
      amendment.types.push('quantity');
      amendment.quantityAdditional = true;
    }
    else {
      quantity = this.state.item.quantity;
    }

    if(this.state.item["amendment_product"] === true && this.state.amendmentProduct && this.state.replacementSelected && this.state.replacementSelected > 0) {
      productId = this.state.replacementSelected;
      amendment.types.push('product');
    }
    else {
      productId = this.state.item["cart_item"]["product"]["id"];
    }

    if(this.state.item["amendment_place"] === true && this.state.amendmentPlace && this.state.amendmentPlaceFormValues && this.state.amendmentPlaceFormValues.place && this.state.amendmentPlaceFormValues.place.id) {
      amendment.place = this.state.amendmentPlaceFormValues.place;
      amendment.types.push('place');
    }
    else {
      amendment.place = this.state.item.place;
    }

    if(this.state.item["amendment_price"] === true && this.state.amendmentPriceContract && this.state.amendmentPriceContractFormValues && this.state.amendmentPriceContractFormValues.price && FormFieldPrice.parseFinalValue(this.state.amendmentPriceContractFormValues.price) > 0) {
      amendment.unitPrice = FormFieldPrice.parseFinalValue(this.state.amendmentPriceContractFormValues.price);
      amendment.types.push('price');
    }

    amendment.clientAccount = this.state.item["client"];
    amendment.client = this.state.item["client"]["partner"];
    amendment.service = { id: this.state.item["id"] };
    amendment.previousCartItem = { id: this.state.item["cart_item"]['id'] };

    this.props.dispatch(addAmendmentAction(productId, quantity, amendment));
    this.props.history.push("/cartSummary");
  }

  planAmendment = () => {
    if(this.state.amendmentFormValues.date) {
      let appliable = false;

      let amendment = {
        cart_item: { id: this.state.item["cart_item"]['id'] },
        applied_at: this.state.amendmentFormValues.date,
        owner: { id: this.props.credentials.id },
        commercial_referrer: { id: this.props.credentials.id }
      }

      if(this.state.amendmentPriceService && this.state.amendmentPriceServiceFormValues.price) {
        amendment["cart_item"]["price"] = FormFieldPrice.parseFinalValue(this.state.amendmentPriceServiceFormValues.price);
        appliable = true;
      }

      if(this.state.amendmentPlace && this.state.amendmentPlaceFormValues.place) {
        amendment["cart"] = {
          place: this.state.amendmentPlaceFormValues.place
        }
        appliable = true;
      }

      let service = {
        id: this.state.item.id,
        amendment: amendment
      };

      if(appliable === true) {
        if(this.state.amendmentPriceService) {
          this.editNewAmendmentServicePriceFromId(this.state.item.id, service);
        }
        if(this.state.amendmentPlace) {
          this.editNewAmendmentFromId(this.state.item.id, service);
        }
      }
    }
  }

  calculateAmendmentType() {
    if(
      this.state.amendmentQuantity === true
      || this.state.amendmentProduct === true
      || (this.state.amendmentPlace === true && this.state.item["amendment_place_type"] === 'c')
      || this.state.amendmentPriceContract === true
    ) {
      return 'c';
    }
    else if(
      (this.state.amendmentPlace === true && this.state.item["amendment_place_type"] === 's')
      || this.state.amendmentPriceService === true
    ) {
      return 's';
    }
    return '';
  }

  displayReplacementChoice() {
    if(this.state.replacementChoices) {
      return (
        <ItemIndex
          type="choice"
          choices={this.state.replacementChoices}
          availableItemDisplay={['card']}
          itemDisplay={productsConf.index.itemDisplay}
          onClickItem={this.onClickReplacement}
          displaySearch={false}
          displayToolbar={false}
          displayPagination={false}/>
      )
    }
  }

  displayAmendmentQuantity() {
    if(!this.state.loading && this.state.item && this.props.credentials.roles.includes('ROLE_QUOTE_CREATE_AMENDMENT')) {
      return (
        <Card bg="main">
          <VerticalCollapse
            collapsed={this.state.amendmentQuantity}>
            <Card.Header>
              <Form.Check
                type="switch"
                id={`form-amendment-quantity`}
                label={`${strings.amendment.custom.quantity}${this.state.item["amendment_quantity"] !== true?strings.amendment.custom.unavailable:""}`}
                disabled={this.state.item["amendment_quantity"] !== true || this.state.amendmentPriceService === true}
                checked={this.state.amendmentQuantity}
                onChange={(event) => this.setState({amendmentQuantity: event.target.checked})}
              />
            </Card.Header>
            <VerticalCollapsed>
              <Card.Body>
                <Row className="justify-content-center">
                  <MyForm
                    ref={this.quantityFormRef}
                    formKey={`form-amendment-quantity`}
                    fields={quantityForm.fields}

                    disabled={false}

                    values={Object.assign({}, this.state.amendmentQuantityFormValues, { oldQuantity: this.state.item.quantity })}
                    onChangeField={this.onChangeQuantityFormValues}

                    displaySubmitButton={false}>
                  </MyForm>
                </Row>
              </Card.Body>
            </VerticalCollapsed>
          </VerticalCollapse>
        </Card>
      )
    }
  }

  displayAmendmentProduct() {
    if(!this.state.loading && this.state.item && this.props.credentials.roles.includes('ROLE_QUOTE_CREATE_AMENDMENT')) {
      return (
        <Card bg="main">
          <VerticalCollapse
            collapsed={this.state.amendmentProduct}>
            <Card.Header>
              <Form.Check
                type="switch"
                id={`form-amendment-product`}
                label={`${strings.amendment.custom.product}${this.state.item["amendment_product"] !== true?strings.amendment.custom.unavailable:""}`}
                disabled={this.state.item["amendment_product"] !== true || this.state.amendmentPriceService === true}
                checked={this.state.amendmentProduct}
                onChange={(event) => this.setState({amendmentProduct: event.target.checked})}
              />
            </Card.Header>
            <VerticalCollapsed>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col xs="auto" md={6} lg={4}>
                    <ItemIndexCard
                      as="div"
                      itemDisplay={productsConf.index.itemDisplay.card}
                      item={this.state.item["cart_item"]["product"]}/>
                  </Col>
                  {this.state.replacementSelected?
                    <>
                      <Col className="d-flex align-items-center" xs="auto">
                        <i className="icon-chevron-right"/>
                      </Col>
                      <Col xs="auto" md={6} lg={4}>
                        <ItemIndexCard
                          as="div"
                          itemDisplay={productsConf.index.itemDisplay.card}
                          item={this.state.replacementChoices.find((item) => item.id === this.state.replacementSelected)}/>
                      </Col>
                    </>
                    :<div/>
                  }
                </Row>
                <VerticalCollapse
                  collapsed={this.state.displayReplacementChoices}>
                  <Row className="justify-content-center">
                    <Form.Check
                      type="switch"
                      id={`form-amendment-product-display-replacement-choices`}
                      label={`Afficher la liste des produits disponible`}
                      disabled={this.state.item["amendment_product"] !== true}
                      checked={this.state.displayReplacementChoices}
                      onChange={(event) => {
                        if(!this.state.replacementChoices) {
                          this.setState(
                            {
                              displayReplacementChoices: event.target.checked,
                              loading: true
                            },
                            () => {
                              let search = {}
                              if(this.state.item["cart_item"]["dependency_master"] && this.state.item["cart_item"]["dependency_master"]['product_dependency'] && this.state.item["cart_item"]["dependency_master"]['product_dependency']['dependency'] && this.state.item["cart_item"]["dependency_master"]['product_dependency']['dependency']['id']) {
                                search['dependency_master_id'] = this.state.item["cart_item"]["dependency_master"]['product_dependency']['dependency']['id'];
                              }
                              else if(this.state.item["cart_item"]["dependency_master"] && this.state.item["cart_item"]["dependency_master"]['product_dependency']) {
                                search['product_dependency_master_id'] = this.state.item["cart_item"]["dependency_master"]['product_dependency']['id'];
                              }
                              else {
                                search['product_sub_family_id'] = this.state.item["cart_item"]["product"]["product_sub_family"]['id'];
                              }
                              this.loadReplacementChoices(1, 0, search)
                            }
                          )
                        }
                        else {
                          this.setState({displayReplacementChoices: event.target.checked})
                        }
                      }}
                    />
                  </Row>
                  <VerticalCollapsed>
                    {this.displayReplacementChoice()}
                  </VerticalCollapsed>
                </VerticalCollapse>
              </Card.Body>
            </VerticalCollapsed>
          </VerticalCollapse>
        </Card>
      )
    }
  }

  displayAmendmentPlace() {
    if(!this.state.loading && this.state.item && ((this.state.item["amendment_place_type"] === 'c' && this.props.credentials.roles.includes('ROLE_QUOTE_CREATE_AMENDMENT')) || this.state.item["amendment_place_type"] === 's')) {
      return (
        <Card bg="main">
          <VerticalCollapse
            collapsed={this.state.amendmentPlace}>
            <Card.Header>
              <Form.Check
                type="switch"
                id={`form-amendment-place`}
                label={`${strings.amendment.custom.place}${this.state.item["amendment_place"] !== true?strings.amendment.custom.unavailable:""}`}
                disabled={this.state.item["amendment_place"] !== true || this.state.amendmentPriceService === true}
                checked={this.state.amendmentPlace}
                onChange={(event) => this.setState({amendmentPlace: event.target.checked})}
              />
            </Card.Header>
            <VerticalCollapsed>
              <Card.Body>
                <Row className="justify-content-center">
                  <MyForm
                    formKey={`form-amendment-place`}
                    fields={placeForm.fields}
                    extraValues={{ client: { id:this.state.item.client.id } }}

                    disabled={false}

                    values={Object.assign({}, this.state.amendmentPlaceFormValues, { old_place: this.state.item.place })}
                    onChangeField={this.onChangePlaceFormValues}

                    displaySubmitButton={false}>
                  </MyForm>
                </Row>
              </Card.Body>
            </VerticalCollapsed>
          </VerticalCollapse>
        </Card>
      )
    }
  }

  displayAmendmentUnitPriceContract() {
    if(!this.state.loading && this.state.item && this.props.credentials.roles.includes('ROLE_QUOTE_CREATE_AMENDMENT')) {
      let oldPrice = this.state.item['cart_item']["unit_price"];
      if(this.state.item['cart_item']["discount_unit"] === '€') {
        oldPrice -= this.state.item['cart_item']["discount"];
      }
      else if(this.state.item['cart_item']["discount_unit"] === '%') {
        oldPrice *= (1 - this.state.item['cart_item']["discount"]/100);
      }
      oldPrice = Math.round(oldPrice*100)/100;
      return (
        <Card bg="main">
          <VerticalCollapse
            collapsed={this.state.amendmentPriceContract}>
            <Card.Header>
              <Form.Check
                type="switch"
                id={`form-amendment-price-contract`}
                label={`${strings.amendment.custom.priceContract}${this.state.item["amendment_price"] !== true?strings.amendment.custom.unavailable:""}`}
                disabled={this.state.item["amendment_price"] !== true || this.state.amendmentPriceService === true}
                checked={this.state.amendmentPriceContract}
                onChange={(event) => this.setState({amendmentPriceContract: event.target.checked})}
              />
            </Card.Header>
            <VerticalCollapsed>
              <Card.Body>
                <Row className="justify-content-center">
                  <MyForm
                    formKey={`form-amendment-price`}
                    fields={priceForm.fields}

                    disabled={false}

                    values={Object.assign({}, this.state.amendmentPriceContractFormValues, { old_price: oldPrice })}
                    onChangeField={this.onChangePriceContractFormValues}

                    displaySubmitButton={false}>
                  </MyForm>
                </Row>
              </Card.Body>
            </VerticalCollapsed>
          </VerticalCollapse>
        </Card>
      )
    }
  }

  displayAmendmentUnitPriceService() {
    if(!this.state.loading && this.state.item && this.props.credentials.roles.includes('ROLE_SERVICE_AMENDMENT_SERVICE_PRICE')) {
      let oldPrice = this.state.item['cart_item']["unit_price"];
      if(this.state.item['cart_item']["discount_unit"] === '€') {
        oldPrice -= this.state.item['cart_item']["discount"];
      }
      else if(this.state.item['cart_item']["discount_unit"] === '%') {
        oldPrice *= (1 - this.state.item['cart_item']["discount"]/100);
      }
      oldPrice = Math.round(oldPrice*100)/100;
      return (
        <Card bg="main">
          <VerticalCollapse
            collapsed={this.state.amendmentPriceService}>
            <Card.Header>
              <Form.Check
                type="switch"
                id={`form-amendment-price-service`}
                label={`${strings.amendment.custom.priceService}${this.state.item["amendment_price"] !== true?strings.amendment.custom.unavailable:""}`}
                disabled={this.state.item["amendment_price"] !== true}
                checked={this.state.amendmentPriceService}
                onChange={(event) => {
                  if(event.target.checked === true) {
                    this.setState({
                      amendmentPriceService: true,
                      amendmentQuantity: false,
                      amendmentProduct: false,
                      amendmentPlace: false,
                      amendmentPriceContract: false
                    })
                  }
                  else {
                    this.setState({
                      amendmentPriceService: false
                    })
                  }
                }}
                />
              </Card.Header>
            <VerticalCollapsed>
              <Card.Body>
                <Row className="justify-content-center">
                  <MyForm
                    formKey={`form-amendment-price`}
                    fields={priceForm.fields}

                    disabled={false}

                    values={Object.assign({}, this.state.amendmentPriceServiceFormValues, { old_price: oldPrice })}
                    onChangeField={this.onChangePriceServiceFormValues}

                    displaySubmitButton={false}>
                  </MyForm>
                </Row>
              </Card.Body>
            </VerticalCollapsed>
          </VerticalCollapse>
        </Card>
      )
    }
  }

  displayAmendmentResult() {
    if(!this.state.loading && this.state.item) {
      return (
        <Card bg="main">
          <Card.Header>{strings.amendment.custom.result}</Card.Header>
          <Card.Body>
            <Row className="justify-content-center">
              <MyForm
                formKey={`form-amendment-price`}
                fields={resultForm.fields}

                disabled={false}

                values={Object.assign({}, this.state.amendmentFormValues, { type: this.calculateAmendmentType() } )}
                onChangeField={this.onChangeFormValues}

                displaySubmitButton={false}>
              </MyForm>
            </Row>
          </Card.Body>
        </Card>
      )
    }
  }

  displayAmendmentContractButton() {
    if(this.calculateAmendmentType() === 'c') {
      return (
        <Button variant="my-information" className="btn-sub-footer"  onClick={this.addAmendmentToCart}>Ajouter au panier</Button>
      )
    }
  }

  displayAmendmentServiceButton() {
    if(this.calculateAmendmentType() === 's') {
      return (
        <Button variant="my-information" className="btn-sub-footer"  onClick={this.planAmendment}>Programmer</Button>
      )
    }
  }

  displayButtons() {
    return (
      <SubFooter>
        {this.displayAmendmentContractButton()}
        {this.displayAmendmentServiceButton()}
      </SubFooter>
    )
  }

  displayAmendment() {
    return (
      <Container>
        {this.displayAmendmentQuantity()}
        {this.displayAmendmentProduct()}
        {this.displayAmendmentPlace()}
        {this.displayAmendmentUnitPriceContract()}
        {this.displayAmendmentUnitPriceService()}
        {this.displayAmendmentResult()}
        {this.displayButtons()}
      </Container>
    )
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        {this.displayLoading()}
        {this.displayAmendment()}
      </MainLayout>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(withRouter(ServiceAmendment)));
