import React from 'react';

import strings from '../../../Localization/Localization';

import MainLayout from '../../Layout/MainLayout';

import { withModalHandler } from '../../../HOC/ModalHandler';

import { connect } from 'react-redux';
import { addBreadcrumbAction } from '../../../Store/Action/breadcrumb';

import { withRouter } from 'react-router-dom';

import { getYousignFromId } from '../../../API/TechnicalAppointment';

import YousignV2 from '../V2/Sign';
import YousignV3 from '../V3/Sign';

import Loading from '../../Layout/Loading';

import {
  technicalTicket as technicalTicketRoutes,
  commercialTicket as commercialTicketRoutes,
  administrativeTicket as administrativeTicketRoutes
} from '../../../MetaData/Route/routes';

/**
 * YousignAppointment
 */
class YousignAppointment extends React.Component {

  constructor(props) {
    super(props);

    this.getAppointment = this.props.modalHandler.addVerificationWithCallback(getYousignFromId, this.postGet);

    this.state = {
      loading: true,
      quote: null
    }
  }

  componentDidMount() {
    this.getAppointment(this.props.match.params.id);

    this.props.dispatch(addBreadcrumbAction(window.location.pathname, strings.yousign.appointment.breadcrumb));
  }

  postGet = (data) => {
    this.setState({
      appointment: data.item,
      loading: false
    })
  }

  displayProcedure() {
    if(this.state.loading === false && this.state.appointment["yousign_procedure"] && this.state.appointment["yousign_procedure"]["uuid"]) {
      return (
        <YousignV2 procedure={this.state.appointment["yousign_procedure"]} memberId={0}/>
      )
    }
  }

  displaySignatureRequest() {
    if(this.state.loading === false && this.state.appointment["yousign_signature_request"] && this.state.appointment["yousign_signature_request"]["uuid"]) {
      return (
        <YousignV3 signatureRequest={this.state.appointment["yousign_signature_request"]} signerId={0}/>
      )
    }
  }

  displayTitle() {
    if(this.state.loading === false) {
      let path = null;
      switch (this.props.ticketType) {
        case 'technical':
          path = technicalTicketRoutes.routes.show.createPath(this.state.appointment["ticket"]["id"])
          break;
        case 'commercial':
          path = commercialTicketRoutes.routes.show.createPath(this.state.appointment["ticket"]["id"])
          break;
        case 'administrative':
          path = administrativeTicketRoutes.routes.show.createPath(this.state.appointment["ticket"]["id"])
          break;
        default:

      }
      return (
        <h3 className="page-title"><a href={path}><i className="icon-chevron-left"/></a>  Signature intervention</h3>
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        {this.displayTitle()}
        {this.displayLoading()}
        {this.displayProcedure()}
        {this.displaySignatureRequest()}
      </MainLayout>
    );
  }
}

export default connect()(withRouter(withModalHandler(YousignAppointment)));
