const commercialBonus = {
  bonusType: {
    label: "Numéro",
    options: {
      quotePunctual: "Ponctuel sur devis",
      quoteAnnual: "Annuel sur devis",
      serviceRecurrentNoEngagement: "Mensuel sur service sans engagement",
    }
  },
  totalTfBase: {
    label: "Base de calcul"
  },
  flatBonusValue: {
    label: "Value de la commission"
  },
  user: {
    label: "Commercial"
  },
  threshold: {
    label: "Seuil"
  },
  createdAtTimeframe: {
    label: "Création"
  },
  receivedAtPrevisionTimeframe: {
    label: "Date de réception prévisionelle"
  },
  receivedAtTimeframe: {
    label: "Date de réception"
  },
  quote: {
    label: "Devis"
  },
  comment: {
    label: "Commentaire"
  },
  receivedAtPrevision: {
    label: "Prévision de réception"
  },
  receivedAt: {
    label: "Réception"
  },
  validated: {
    label: "Commission validée?"
  }
};

export default commercialBonus;
