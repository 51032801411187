// import strings from '../../../Localization/Localization';

import { ParameterTypes } from '../../../Dashboard/Constant';

export const transformFieldToParamaterChoice = (
  field,
  value = true,
  parameterList = true,
  parameterBagType = null,
  userSelf = false
) => {

  let parameterChoiceField = {
    fieldKey: field.fieldKey,
    type: 'parameterChoice',
    label: field.label,
    validators: [],

    subType: field.type,
    subProps: {
      ...field
    },
    choices: {}
  }
  if(value === true) {
    parameterChoiceField.choices.value = {};
  }
  if(parameterList === true) {
    parameterChoiceField.choices.parameterList = {
      defaultNumberOfField: 3,
      allowMoreField: null,

      label: "Choix #key#"
    };
  }
  if(Object.values(ParameterTypes).includes(parameterBagType)) {
    parameterChoiceField.choices.parameterBag = {
      parameterType: parameterBagType
    };
  }
  if(userSelf) {
    parameterChoiceField.choices.userSelf = {};
  }

  return parameterChoiceField;
}
