import React from 'react';

import strings from '../../../Localization/Localization';

import { Container } from 'react-bootstrap';

import MainLayout from '../../Layout/MainLayout';
import MyForm from '../../Form/MyForm';

import { withModalHandler } from '../../../HOC/ModalHandler';

import { withRouter } from "react-router-dom";

import { connect } from 'react-redux';
import { addBreadcrumbAction } from '../../../Store/Action/breadcrumb';

import Loading from '../../Layout/Loading';

/**
 * NewItemForm
 *
 * This component allow to create new items.
 */
class NewItemForm extends React.Component {

  constructor(props) {
    super(props);

    //state of the component, including :
    // - loading : when the component is creating the new item
    // - item : new item
    this.state = {
      loading: false,

      item: props.conf.empty
    }

    this.newItem = this.props.modalHandler.addVerificationWithCallback(props.conf.api, this.postNew, this.postNewFailure);
  }

  componentDidMount() {
    this.props.dispatch(addBreadcrumbAction(window.location.pathname, this.props.conf.pageTitle?this.props.conf.pageTitle:strings.common.new));
  }

  /**
   * callback used by the form to change the item
   *
   * @param fieldKey the fieldkey to change change in the form
   * @param value the new value of the form
   */
  onChangeItem = (fieldKey, value) => {
    let item = Object.assign({}, this.state.item)
    item[fieldKey] = value
    this.setState({
      item: item,
    })
  }

  /**
   * callback used by the form on submit
   */
  onSubmit = (values) => {
    this.setState({
      loading: true
    }, () => {
      this.props.conf.onSubmit(values);
      this.newItem(values);
    })
  }

  /**
   * callback after editing the data.
   *
   * @param data data from the edit call
   */
  postNew = (data) => {
    if(this.props.conf.postNew.type === 'path'){
      this.props.history.push(this.props.conf.postNew.path(data.item.id));
    }
    else {
      this.setState({
        loading: false
      })
    }
  }

  /**
   * callback after failed creation.
   */
  postNewFailure = (msg) => {
    this.setState({
      loading: false
    })
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        {this.displayLoading()}
        <Container className="d-flex flex-column align-items-center">
          <MyForm
            formKey={this.props.conf.form.formKey}
            fields={this.props.conf.form.fields}
            extraValues={this.props.conf.form.extraValues}
            extraData={this.props.conf.form.extraData}
            values={this.state.item}
            onChangeField={this.onChangeItem}
            onSubmit={this.onSubmit}
          />
        </Container>
      </MainLayout>
    );
  }
}

export default connect()(withModalHandler(withRouter(NewItemForm)));
