import React from 'react';

import '../../../css/yousign.css';

import { connect } from 'react-redux';

import Yousign from '../../../plugin/yousign-v3/iframe-sdk-1.6.0';

/**
 * Sign
 *
 * This component managed yousign v3 signature
 */
class Sign extends React.Component {

  static defaultProps = {
    signatureRequest: null,
    signerId: null
  }

  getContainerId() {
    return "yousign-container";
  }

  getSignatureLink() {
    if(this.props.signatureRequest && this.props.signatureRequest["signers"]) {
      let signer = this.props.signatureRequest["signers"].find(signer => signer.user.id === this.props.credentials.id);
      if(!signer && this.props.signerId !== null) {
        signer = this.props.signatureRequest["signers"][this.props.signerId];
      }
      if(signer) {
        return signer["signature_link"]+(process.env.REACT_APP_YOUSIGN_V3_SANDBOX?"&disable_domain_validation=true":"")
      }
    }
    return "";
  }

  componentDidMount() {
    this.yousign = new Yousign({
      signatureLink: this.getSignatureLink(),
      iframeContainerId: this.getContainerId(),
      isSandbox: process.env.REACT_APP_YOUSIGN_V3_SANDBOX?true:false,
      iFrameAttributes: {
        referrerPolicy: 'origin-when-cross-origin'
      },
    });
  }

  displaySign() {
    return <div id={this.getContainerId()} className="yousign-container"/>
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displaySign()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(Sign);
