const product = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Ce nom ne doit pas être vide.",
      assertMax: "Ce nom est trop long."
    },
  },
  code: {
    label: "Code",
    invalid: {
      assertEmpty: "Ce code ne doit pas être vide.",
      assertMax: "Ce code est trop long.",
      assertUnique: "Ce code est déjà utilisé."
    }
  },
  description: {
    label: "Description",
    invalid: {
      assertMax: "Cette description est trop longue."
    }
  },
  img: {
    label: "Image"
  },
  activeOrder: {
    label: "Activer pour les commandes ?",
  },
  activeQuote: {
    label: "Activer pour les devis ?",
  },
  activeTicket: {
    label: "Activer pour les tickets ?",
  },
  orderBreaking: {
    label: "Activer la séparation en commande ?",
  },
  serviceTracking: {
    label: "Activer le tracking de service ?",
  },
  serviceBreaking: {
    label: "Activer la séparation des services ?",
  },
  rentable: {
    label: "Activer la location ?",
  },
  displayMainIndex: {
    label: "Visible dans l'index produit ?",
  },
  vatPercentage: {
    label: "Pourcentage de T.V.A.",
  },
  productFamily: {
    label: "Famille de produit",
    invalid: {
      assertEmpty: "La famille de produit doit être renseigné.",
    }
  },
  productSubFamily: {
    label: "Sous famille de produit",
    invalid: {
      assertEmpty: "La sous famille de produit doit être renseigné.",
      assertFamily: "La sous famille ne correspond pas à la famille.",
    }
  },
  constructor: {
    label: "Constructeur",
  },
  price: {
    label: "Prix",
  },
  minPrice: {
    label: "Prix minimal de vente",
    invalid: {
      assertEmpty: "Le prix minimal doit être au moins 0€.",
      assertMax: "Le prix minimal ne peut pas être supérieur au prix de vente."
    }
  },
  buyingPrice: {
    label: "Prix d'achat",
  },
  priceType: {
    label: "Type de prix",
    options: {
      option_1: 'Location',
      option0: 'Vente',
      option1: 'Abonnement',
      option2: 'FAS'
    }
  },
  buyingPrices: {
    label: "Prix d'achat",
    allowMoreField: {
      addLabel: "Ajouter un prix",
      removeLabel: "Enlever un prix",
      removingLabel: "Supprimer"
    }
  },
  productMasters: {
    label: "Produit principal",
    allowMoreField: {
      addLabel: "Ajouter un produit principal",
      removeLabel: "Enlever un produit principal",
      removingLabel: "Supprimer"
    }
  },
  productSlaves: {
    label: "Accesoires",
    allowMoreField: {
      addLabel: "Ajouter un accessoire",
      removeLabel: "Enlever un accessoire",
      removingLabel: "Supprimer"
    }
  },
  specificConditionsOfSale: {
    label: "Conditions particulières de vente"
  },
  phonePackage: {
    label: ""
  },
  createdAtMin: {
    label: "Date de création (min)"
  },
  createdAtMax: {
    label: "Date de création (max)"
  },
  updatedAtMin: {
    label: "Date de modification (min)"
  },
  updatedAtMax: {
    label: "Date de modification (max)"
  },
  displayHidden: {
    label: "Afficher les produits cachés ?"
  }
};

export default product;
