import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  statistic as baseStatistic,
  // newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of commercial bonus based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('commercial_bonuses', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single commercial bonus based on his ID
 *
 * @param id the of the account requested
 */
export function getFromId(id) {
  return baseGetFromId('commercial_bonuses', id);
}

/**
 * Get a single commercial bonus based on his ID
 *
 * @param id the of the commercial bonus requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('commercial_bonuses', id, 'identification');
}

/**
 * Get a single commercial bonus based on his ID
 *
 * @param id the of the commercial bonus requested
 */
export function getValidationFromId(id) {
  return baseGetFromId('commercial_bonuses', id, 'validation');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single appointment type based on his ID
 *
 * @param id the of the appointment type requested
 * @param data the new data of the appointment type
 */
export function editValidationFromId(id, data) {
  return baseEditFromId('commercial_bonuses', id, data, 'validation');
}

 /******************************************************************************
  * STATISTIC FUNCTION                                                         *
  ******************************************************************************/

 export function statistic(stat, attribute, search) {
   return baseStatistic('commercial_bonuses', stat, attribute, search);
 }

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/
