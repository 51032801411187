import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.document.name.label,
  validators: []
}

let docType = {
  fieldKey: "doc_type",
  label: strings.form.document.docType.label,
  type: "dropdown",
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 'gtc',
      label: strings.form.document.docType.options.generalTermsAndConditionsOfSale
    },
    {
      value: 'stc',
      label: strings.form.document.docType.options.specificTermsAndConditionsOfSale
    }
  ],
}

let file = {
  fieldKey: "document",
  type: "file",
  label: strings.form.document.file.label,
  validators: []
}

let _private = {
  fieldKey: "private",
  type: "switch",
  label: strings.form.document.private.label,

  showOnDisabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return credentials.roles.includes('ROLE_NYUKOM');
  },
  disabled: (getValues, getExtraValues) => {
    console.log(getExtraValues());
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },

  validators: [],
}

let applicableFrom = {
  fieldKey: "applicable_from",
  type: "date",
  label: strings.form.phonePrice.applicableFrom.label,
  validators: []
}

let applicableUntil = {
  fieldKey: "applicable_until",
  type: "date",
  label: strings.form.phonePrice.applicableUntil.label,
  validators: []
}

export const newForm = {
  formKey: "document",
  fields: [
    name,
    docType,
    file,
    _private,
    applicableFrom,
    applicableUntil
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const form = {
  formKey: "document",
  fields: [
    Object.assign({}, name, {
      disabled: true
    }),
    Object.assign({}, docType, {
      disabled: true
    }),
    Object.assign({}, file, {
      disabled: true
    }),
    _private,
    Object.assign({}, applicableFrom, {
      disabled: true
    }),
    applicableUntil
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
