import strings from '../../Localization/Localization';

import { fetchPlaces } from '../../API/Places';
import { fetchCountries } from '../../API/Countries';
import { fetchCodeNafs } from '../../API/CodeNafs';
import { fetchMainUsers } from '../../API/Users';
import { fetchPartnerAccounts } from '../../API/PartnerAccounts';

import { addressForm as _placeForm } from './places';
import { form as _bankInformationForm } from './bankInformation';
import {
  form as _accountForm,
  accountingForm as _accountAccountingForm,
  specificPricesForm as _accountSpecficPricesForm
} from './partnerAccounts';

import {
  places as placesRoutes
} from '../Route/routes';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.partner.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.partner.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let legalForm = {
  fieldKey: "legal_form",
  type: "text",
  label: strings.form.partner.legalForm.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.partner.legalForm.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let siret = {
  fieldKey: "siret",
  type: "text",
  label: strings.form.partner.siret.label,
  validators: [
    {
      id: 'assertFormat',
      type: 'regex',
      conf: {
        regex: /.{9,15}/
      },
      invalidFeedback: strings.form.partner.siret.invalid.assertFormat,
      defaultValue: false
    },
  ]
}

let vatIntra = {
  fieldKey: "vat_intra",
  type: "text",
  label: strings.form.partner.vatIntra.label,
  validators: []
}

let prospect = {
  fieldKey: "prospect",
  type: "switch",
  label: strings.form.partner.prospect.label,
  validators: []
}

let newMainPlace = {
  fieldKey: "main_place",
  type: "subform",
  label: strings.form.partner.mainPlace.label,

  subForm: _placeForm,

  validators: []
}

let mainPlace = {
  fieldKey: "main_place",
  type: "dropdownEntity",
  label: strings.form.partner.mainPlace.label,

  disabledLink: (value, getValues) => value?placesRoutes.routes.show.createPath(value["id"]):null,

  validators: [],

  showEmpty: true,
  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "places",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues()["id"],
    loaderArguments: (value, getValues) => [{
      partner_id: getValues()["id"]
    }],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let newBillingPlace = {
  fieldKey: "billing_place",
  type: "subform",
  label: strings.form.partner.billingPlace.labelNew,

  subForm: _placeForm,
  ignoreSubFormValidation: true,

  validators: []
}

let billingPlace = {
  fieldKey: "billing_place",
  type: "dropdownEntity",
  label: strings.form.partner.billingPlace.label,

  disabledLink: (value, getValues) => value?placesRoutes.routes.show.createPath(value["id"]):null,

  validators: [],

  showEmpty: true,
  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "places",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues()["id"],
    loaderArguments: (value, getValues) => [{
      partner_id: getValues()["id"]
    }],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let nationality = {
  fieldKey: "nationality",
  type: "dropdownEntity",
  label: strings.form.partner.nationality.label,

  validators: [],

  showEmpty: true,
  loader: {
    id: "places",
    loader: fetchCountries,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let codeNaf = {
  fieldKey: "code_naf",
  type: "dropdownEntity",
  label: strings.form.partner.codeNaf.label,

  ignoreValidation: true,
  validators: [],

  showEmpty: true,
  loader: {
    id: "codeNaf",
    loader: fetchCodeNafs,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["code"],
    getValue: (data) => data["id"],
    getLabel: (data) => `${data["code"]} - ${data["description"]}`,
  },
}

let places = {
  fieldKey: "places",
  type: "array",
  label: strings.form.partner.places.label,

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.partner.places.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.partner.places.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.partner.places.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: strings.form.partner.place.label,

    subForm: _placeForm,

    validators: [],
  },

  validators: []
}

let accounts = {
  fieldKey: "accounts",
  type: "array",
  label: strings.form.partner.accounts.label,

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.partner.accounts.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.partner.accounts.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.partner.accounts.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: strings.form.partner.account.label,

    subForm: _accountForm,

    validators: [],
  },

  validators: []
}

let partnerAcountChoice = {
  fieldKey: "partner_account",
  type: "dropdownEntity",
  label: strings.form.partner.accounts.label,

  validators: [],

  showEmpty: true,
  loader: {
    id: "account",
    loader: fetchPartnerAccounts,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let accountsSpecificPrices = {
  fieldKey: "accounts",
  type: "array",
  label: strings.form.partner.accounts.label,

  defaultNumberOfField: 0,
  allowMoreField: false,

  subField: {
    type: "subform",
    label: strings.form.partner.account.label,

    subForm: _accountSpecficPricesForm,

    validators: [],
  },

  validators: []
}

let productSubFamilyDistributionInBill = {
  fieldKey: "product_sub_family_distribution_in_bill",
  type: "switch",
  label: strings.form.partner.productSubFamilyDistributionInBill.label,
  validators: []
}

let accountsAccounting = {
  fieldKey: "accounts",
  type: "array",
  label: strings.form.partner.accounts.label,

  defaultNumberOfField: 0,
  allowMoreField: false,

  subField: {
    type: "subform",
    label: strings.form.partner.account.label,

    subForm: _accountAccountingForm,

    validators: [],
  },

  validators: []
}

let bankInformation = {
  fieldKey: "bank_information",
  type: "subform",
  label: "",

  subForm: _bankInformationForm,

  validators: []
}

let commercialReferrer = {
  fieldKey: "commercial_referrer",
  label: strings.form.partner.commercialReferrer.label,
  type: "dropdownEntity",

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.partner.commercialReferrer.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  loader: {
    id: "mainUser",
    loader: fetchMainUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

export const newForm = {
  formKey: "partner",
  fields: [
    name,
    legalForm,
    siret,
    vatIntra,
    prospect,
    commercialReferrer,
    newMainPlace,
    newBillingPlace,
    places,
    accounts
  ]
}

export const form = {
  formKey: "partner",
  fields: [
    name,
    legalForm,
    siret,
    vatIntra,
    prospect,
    codeNaf,
    commercialReferrer,
    nationality,
    mainPlace,
    billingPlace,
  ]
}

export const placesForm = {
  formKey: "partner",
  fields: [
    places
  ]
}

export const accountsForm = {
  formKey: "partner",
  fields: [
    accounts
  ]
}

export const accountingForm = {
  formKey: "partner",
  fields: [
    productSubFamilyDistributionInBill,
    accountsAccounting
  ]
}

export const bankInformationForm = {
  formKey: "partner",
  fields: [
    bankInformation
  ]
}

export const specificPricesForm = {
  formKey: "partner",
  fields: [
    accountsSpecificPrices
  ]
}

export const partnerAccountChoiceForm = {
  formKey: "partner",
  fields: [
    partnerAcountChoice
  ]
}
