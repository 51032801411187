import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import {
  users as usersRoutes,
  quotes as quotesRoutes,
  commercialBonusThreshold as thresholdsRoutes
} from '../Route/routes';

export let bonusType = {
  fieldKey: "bonus_type",
  label: strings.form.commercialBonus.bonusType.label,
  type: "dropdown",
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.commercialBonus.bonusType.options.quotePunctual
    },
    {
      value: 1,
      label: strings.form.commercialBonus.bonusType.options.quoteAnnual
    },
    {
      value: 2,
      label: strings.form.commercialBonus.bonusType.options.serviceRecurrentNoEngagement
    }
  ],
}

let totalTfBase = {
  fieldKey: "total_tf_base",
  type: "price",
  label: strings.form.commercialBonus.totalTfBase.label,
  validators: []
}

let flatBonusValue = {
  fieldKey: "flat_bonus_value",
  type: "price",
  label: strings.form.commercialBonus.flatBonusValue.label,
  validators: []
}

let user = {
  fieldKey: "user",
  label: strings.form.commercialBonus.user.label,
  type: "dropdownEntity",
  disabled: () => true,

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && !Array.isArray(value) && credentials.roles.includes('ROLE_NYUKOM') && (credentials.roles.includes('ROLE_USER_ADMIN') || credentials.roles.includes('ROLE_USER_INDEX'))) {
        return usersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let origins = {
  fieldKey: "origins",
  label: "",
  type: "array",
  disabled: () => true,

  validators: [],

  subField: {
    type: "dropdownEntity",
    label: strings.form.commercialBonus.quote.label,

    disabledLink: (value, getValues, getExtraValues) => {
      if(getExtraValues().credentials) {
        let credentials = getExtraValues().credentials();
        if(value && !Array.isArray(value) && credentials.roles.includes('ROLE_QUOTE')) {
          return quotesRoutes.routes.show.createPath(value["id"]);
        }
      }
      return false;
    },

    validators: [],

    showEmpty: true,
    loader: null,

    adapter: {
      requirement: (data) => data["id"] && data["number"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["number"],
    },
  }
}

let threshold = {
  fieldKey: "threshold",
  label: strings.form.commercialBonus.threshold.label,
  type: "dropdownEntity",
  disabled: () => true,

  disabledLink: (value, getValues, getExtraValues) => {
    if(value && !Array.isArray(value)) {
      return thresholdsRoutes.routes.show.createPath(value["id"]);
    }
    return false;
  },

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"],
    getValue: (data) => data["id"],
    getLabel: (data) => data && `${strings.form.commercialBonus.threshold.label}: ${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(data["total_tf_min"])} - ${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(data["total_tf_max"])} -> ${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(data["flat_bonus"])}`,
  },
}

let comment = {
  fieldKey: "comment",
  type: "textarea",
  label: strings.form.commercialBonus.comment.label,
  validators: []
}

let receivedAt = {
  fieldKey: "received_at",
  type: "date",
  label: strings.form.commercialBonus.receivedAt.label,
  validators: []
}

let receivedAtPrevision = {
  fieldKey: "received_at_prevision",
  type: "date",
  label: strings.form.commercialBonus.receivedAtPrevision.label,
  validators: []
}

let validated = {
  fieldKey: "validated",
  type: "triswitch",
  label: strings.form.commercialBonus.validated.label,
  validators: []
}

export const validationForm = {
  formKey: "commercialBonus",
  fields: [
    comment,
    validated,
    receivedAtPrevision,
    receivedAt
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const form = {
  formKey: "commercialBonus",
  fields: [
    bonusType,
    validated,
    origins,
    threshold,
    totalTfBase,
    flatBonusValue,
    user,
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
