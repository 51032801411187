import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import {
  partners as partnersRoutes,
  users as usersRoutes
} from '../Route/routes';

import { fetchTicketTypes as fetchAdministrativeTicketTypes } from '../../API/AdministrativeTicketTypes';
import { fetchTicketTypes as fetchCommercialTicketTypes } from '../../API/CommercialTicketTypes';
import { fetchTicketTypes as fetchTechnicalTicketTypes } from '../../API/TechnicalTicketTypes';

import { fetchPartners } from '../../API/Partners';
import { fetchPartnerAccounts } from '../../API/PartnerAccounts';
import { fetchPlaces } from '../../API/Places';
import {
  fetchMainUsers,
  fetchPartnerUsers
} from '../../API/Users';

import { form as _ticketOriginForm } from './ticketOrigins';
import { newForm as _newAppointmentForm } from './appointments';

let description = {
  fieldKey: "description",
  type: "textarea",
  label: strings.form.ticket.description.label,
  validators: [],
  defaultValue: ""
}

let ticketType = (type) => {
  let loader = null;
  switch (type) {
    case 'technical':
      loader = fetchTechnicalTicketTypes;
      break;
    case 'commercial':
      loader = fetchCommercialTicketTypes;
      break;
    case 'administrative':
      loader = fetchAdministrativeTicketTypes;
      break;
    default:
  }
  return {
    fieldKey: "request_type",
    label: strings.form.ticket.ticketType.label,
    type: "dropdownEntity",

    validators: [
      {
        id: 'assertEmpty',
        type: 'notEmptyOrNull',
        invalidFeedback: strings.form.ticket.ticketType.invalid.assertEmpty,
        defaultValue: false
      },
    ],

    showEmpty: true,
    loader: {
      id: "ticketType",
      loader: loader,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  }
}

let criticity = {
  fieldKey: "criticity",
  type: "dropdown",
  label: strings.form.ticket.criticity.label,

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.ticket.criticity.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 1,
      label: strings.form.ticket.criticity.options.urgent
    },
    {
      value: 2,
      label: strings.form.ticket.criticity.options.medium
    },
    {
      value: 3,
      label: strings.form.ticket.criticity.options.noturgent
    },
  ],
}

let origin = {
  fieldKey: "ticket_origin",
  type: "subform",
  label: "",
  validators: [],

  subForm: _ticketOriginForm
}

let client = {
  fieldKey: "client",
  label: strings.form.ticket.client.label,
  type: "dropdownEntity",

  disabledLink: (value) => value && partnersRoutes.routes.show.createPath(value["id"]),

  validators: [],

  showEmpty: true,
  loader: {
    id: "client",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientAccount = {
  fieldKey: "client_account",
  label: strings.form.ticket.clientAccount.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues().client === undefined || getValues().client === null || getValues().client.id === undefined,

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component.reloadData()
    },
    {
      id: "reload",
      event: "onChange",
      target: "client_account",
      function: (component) => {
        if(component.props.getValues()["ticket_origin"] && component.props.getValues()["ticket_origin"]["id"]) {
          component.props.modifyField("ticket_origin", {
            id: component.props.getValues()["ticket_origin"]["id"]
          });
        }
        else if(component.props.getValues()["ticket_origin"]) {
          component.props.modifyField("ticket_origin", undefined);
        }
      }
    }
  ],

  showEmpty: true,
  loader: {
    id: "clientAccount",
    loader: fetchPartnerAccounts,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let place = {
  fieldKey: "place",
  label: strings.form.ticket.place.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues().client === undefined || getValues().client === null || getValues().client.id === undefined,

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component.reloadData()
    },
    {
      id: "reload",
      event: "onChange",
      target: "place",
      function: (component) => {
        if(component.props.getValues()["ticket_origin"] && component.props.getValues()["ticket_origin"]["id"]) {
          component.props.modifyField("ticket_origin", {
            id: component.props.getValues()["ticket_origin"]["id"]
          });
        }
        else if(component.props.getValues()["ticket_origin"]) {
          component.props.modifyField("ticket_origin", undefined);
        }
      }
    }
  ],

  showEmpty: true,
  loader: {
    id: "partnerPlaces",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"],
        active: 1
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let refereeClients = {
  fieldKey: "referee_clients",
  label: "",
  type: "array",

  submitRemoval: true,
  removalCondition: (value) => !!value,
  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.ticket.refereeClients.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.ticket.refereeClients.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.ticket.refereeClients.allowMoreField.removingLabel,

  validators: [],

  subField: {
    label: strings.form.ticket.refereeClients.label,
    type: "dropdownEntity",
    disabled: (getValues) => getValues().client === undefined || getValues().client === null || getValues().client.id === undefined,

    disabledLink: (value, getValues, getExtraValues) => {
      if(getExtraValues().credentials) {
        let credentials = getExtraValues().credentials();
        if(value && (credentials.roles.includes('ROLE_USER_ADMIN') || credentials.roles.includes('ROLE_USER_INDEX'))) {
          return usersRoutes.routes.show.createPath(value["id"]);
        }
      }
      return false;
    },

    validators: [],

    events: [
      {
        id: "reload",
        event: "onChange",
        target: "client",
        function: (component) => component.reloadData()
      }
    ],

    showEmpty: true,
    filterOptions: (item, getValues) => !getValues(1)['referee_clients'] || !getValues(1)['referee_clients'].some((value) => value && value.id === item.id),
    loader: {
      id: "refereeClients",
      loader: fetchPartnerUsers,
      loaderRequire: (value, getValues) => getValues() && getValues()["client"] && getValues()["client"]["id"],
      loaderArguments: (value, getValues) => {
        return [{
          partner_id: getValues()["client"]["id"]
        }]
      },
    },

    adapter: {
      requirement: (data) => data["id"] && data["displayname"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["displayname"],
    },
  }
}

let referee = {
  fieldKey: "referee",
  label: strings.form.ticket.referee.label,
  type: "dropdownEntity",
  disabled: () => true,

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && !Array.isArray(value) && credentials.roles.includes('ROLE_NYUKOM') && (credentials.roles.includes('ROLE_USER_ADMIN') || credentials.roles.includes('ROLE_USER_INDEX'))) {
        return usersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let editReferee = (type) => {
  let requiredRole = null;
  switch (type) {
    case 'technical':
      requiredRole = "ROLE_TECHNICAL_TICKET_EDIT";
      break;
    case 'commercial':
      requiredRole = "ROLE_COMMERCIAL_TICKET_EDIT";
      break;
    case 'administrative':
      requiredRole = "ROLE_ADMINISTRATIVE_TICKET_EDIT";
      break;
    default:

  }
  return {
    fieldKey: "referee",
    label: strings.form.ticket.referee.label,
    type: "dropdownEntity",

    validators: [],

    showEmpty: true,
    filterOptions: (option) => Array.isArray(option.roles) && option.roles.includes(requiredRole),
    loader: {
      id: "referees",
      loader: fetchMainUsers,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => data["id"] && data["displayname"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["displayname"],
    },
  }

}

let newAppointment = (type) => {
  return {
    fieldKey: "appointment",
    type: "subform",
    label: "",
    validators: [],

    subForm: _newAppointmentForm(type)
  }
}

export const form = (type) => {
  let originFields = [];
  switch (type) {
    case 'technical':
      originFields = [
        origin
      ];
      break;
    default:
  }
  return {
    formKey: "ticket",
    fields: [
      client,
      clientAccount,
      place,
      refereeClients,
      referee,
      ...originFields,
      description,
      ticketType(type),
      criticity,
    ],
    extraValues: {
      credentials: () => store.getState().persisted.credentials
    }
  }
}

export const newForm = (type) => {
  let originFields = [];
  switch (type) {
    case 'technical':
      originFields = [
        origin
      ];
      break;
    default:
  }
  return {
    formKey: "ticket",
    fields: [
      client,
      clientAccount,
      place,
      Object.assign({}, refereeClients, {
        disabled: (getValues, getExtraValues) => {
          if(getExtraValues().credentials) {
            let credentials = getExtraValues().credentials();
            return !credentials.roles.includes('ROLE_NYUKOM') && !credentials.roles.includes('ROLE_USER_INDEX');
          }
          return true;
        },
        showOnDisabled: () => false,
      }),
      ...originFields,
      description,
      Object.assign({}, ticketType(type), {
        filterOptions: (item) => {
          if(item["id"] === 1 || item["id"] === 2) {
            return false;
          }
          return true;
        }
      }),
      criticity,
    ],
    extraValues: {
      credentials: () => store.getState().persisted.credentials
    }
  }
}

export const newAppointmentForm = (type) => {
  return {
    formKey: "newAppointment",
    fields: [
      newAppointment(type)
    ]
  }
}

export const assignRefereeForm = (type) => {
  return {
    formKey: "newAppointment",
    fields: [
      editReferee(type)
    ]
  }
}
