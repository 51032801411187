import React from 'react';

import { Button } from 'react-bootstrap';

import TableRowCollapsedElement from './TableRowCollapsedElement';
import TableRowCollapsedRow from './TableRowCollapsedRow';

/**
 * TableRow
 */
class TableRowWithCollapse extends React.Component {

  static defaultProps = {
    className: "row-my-primary",
    as: 'a',

    subRows: false
  }

  constructor(props) {
    super(props);

    this.divEl = React.createRef();

    this.state = {
      hovering: false,
      height: -1,

      // subRowsheight: -1
      displaySubRows: false
    }
  }

  componentDidMount() {
    this.updateHeight();
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  updateHeight() {
    if(this.state.height !== this.divEl.clientHeight) {
      this.setState({
        height: this.divEl.clientHeight
      })
    }
  }

  displayMain() {
    switch (this.props.as) {
      case 'button':
        return this.displayAsButton();
      case 'a':
        return this.displayAsA();
      default:
        return this.displayAsDefault();
    }
  }

  displayHandle() {
    if(this.props.subRows) {
      if(this.props.children.some((child) => child.type === TableRowCollapsedRow)) {
        return (
          <td><Button variant="my-secondary-noline" className="table-row-collapse-button-sub-rows" onClick={() => this.setState({displaySubRows: !this.state.displaySubRows})}><i className="icon-plus"/></Button></td>
        )
      }
      return <td/>
    }
  }

  displayAsButton() {
    if(this.props.as === 'button') {
      return (
        <tr
          className={this.props.className}
          onMouseEnter={(event) => this.setState({ hovering: true })}
          onMouseLeave={(event) => this.setState({ hovering: false })}
          onClick={() => this.props.onClickItem(this.props.data.id, this.props.data)}>
          {this.displayHandle()}
          {this.props.children.filter((child) => child.type !== TableRowCollapsedElement && child.type !== TableRowCollapsedRow)}
        </tr>
      )
    }
  }

  displayAsA() {
    if(this.props.as === 'a') {
      return (
        <tr
          className={this.props.className}
          onMouseEnter={(event) => this.setState({ hovering: true })}
          onMouseLeave={(event) => this.setState({ hovering: false })}>
          {this.displayHandle()}
          {this.props.children
            .filter((child) => child.type !== TableRowCollapsedElement && child.type !== TableRowCollapsedRow)
            .map((child) => {
              return React.cloneElement(child, {
                href: child.props.href?child.props.href:this.props.entityPath(this.props.data.id, this.props.data)
              })
            })
          }
        </tr>
      )
    }
  }

  displayAsDefault() {
    return (
      <tr className={this.props.className}>
        {this.displayHandle()}
        {this.props.children.filter((child) => child.type !== TableRowCollapsedElement && child.type !== TableRowCollapsedRow)}
      </tr>
    )
  }

  displaySubRows() {
    switch (this.props.as) {
      case 'button':
        return this.displaySubRowsAsButton();
      case 'a':
        return this.displaySubRowsAsA();
      default:
        return this.displaySubRowsAsDefault();
    }
  }

  displaySubRowsHandle() {
    if(this.props.subRows) {
      if(this.props.children.some((child) => child.type === TableRowCollapsedRow)) {
        return (
          <td><i className="icon-skip"/></td>
        )
      }
      return <td/>
    }
  }

  displaySubRowsAsButton() {
    if(this.props.as === 'button') {
      return (
        <>
          {this.props.children
            .filter((child) => child.type === TableRowCollapsedRow)
            .map((child, i) => {
              return (
                <tr
                  key={i}
                  className={this.props.className}
                  onMouseEnter={(event) => this.setState({ hovering: true })}
                  onMouseLeave={(event) => this.setState({ hovering: false })}
                  onClick={() => this.props.onClickItem(child.props.data.id, child.props.data)}>
                  {this.displaySubRowsHandle()}
                  {child.props.children.filter((child) => child.type !== TableRowCollapsedElement && child.type !== TableRowCollapsedRow)}
                </tr>
              )
            })
          }
        </>
      )
    }
  }

  displaySubRowsAsA() {
    if(this.props.as === 'a') {
      let style = {
        display: "none",
      };
      let displayStyle = {
      };
      return (
        <>
          {this.props.children
            .filter((child) => child.type === TableRowCollapsedRow)
            .map((child, i) => {
              return (
                <tr
                  key={i}
                  className={this.props.className}
                  onMouseEnter={(event) => this.setState({ hovering: true })}
                  onMouseLeave={(event) => this.setState({ hovering: false })}
                  style={(this.state.displaySubRows)?displayStyle:style}>
                  {this.displaySubRowsHandle()}
                  {child.props.children
                    .map((child) => {
                      return React.cloneElement(child, {
                        href: this.props.entityPath(child.props.data.id, child.props.data)
                      })
                    })
                  }
                </tr>
              )
            })
          }
        </>
      )
    }
  }

  displaySubRowsAsDefault() {
    if(this.props.as === 'a') {
      let style = {
        display: "none",
      };
      let displayStyle = {
      };
      return (
        <>
          {this.props.children
            .filter((child) => child.type === TableRowCollapsedRow)
            .map((child, i) => {
              return (
                <tr
                  key={i}
                  className={this.props.className}
                  onMouseEnter={(event) => this.setState({ hovering: true })}
                  onMouseLeave={(event) => this.setState({ hovering: false })}
                  style={(this.state.displaySubRows)?displayStyle:style}>
                  {this.displaySubRowsHandle()}
                  {child.props.children}
                </tr>
              )
            })
          }
        </>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    let tdStyle = {
      overflow: "hidden",
      width: "100%",
    }
    let style = {
      marginTop: "-"+(this.state.height+1)+"px",
      width: "100%",
      transition: "margin 0.3s",
    };

    let hoverStyle = {
      marginTop: "0px",
      width: "100%",
      transition: "margin 0.3s",
    };
    let collapsedElement = this.props.children.find((child) => child.type === TableRowCollapsedElement);
    return (
      <>
        {this.displayMain()}
        {this.displaySubRows()}
        <tr
          onMouseEnter={(event) => this.setState({ hovering: true })}
          onMouseLeave={(event) => this.setState({ hovering: false })}
          className="table-collapsed-row">
          <td style={tdStyle} colSpan={this.props.nbOfColumns + (this.props.subRows?1:0)}>
            <div
              ref={el => this.divEl = el}
              style={(this.state.hovering)?hoverStyle:style}>
              {collapsedElement?collapsedElement.props.children:undefined}
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export default TableRowWithCollapse;
