import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getConfigurationFromId,
  getConfigurationEditFromId,
  getAccountingFromId,
  getDatasheetFromId,
  getTechnicalDataFromId,
  getAPIFromId,
  getSpecificConditionsOfSaleFromId,
  editFromId,
  editConfigurationFromId,
  editAccountingFromId,
  editDatasheetFromId,
  editTechnicalDataFromId,
  editAPIFromId,
  editSpecificConditionsOfSaleFromId,
  newItem
} from '../../API/ProductSubFamilies';
import {
  form,
  configurationForm,
  configurationEditForm,
  accountingForm,
  datasheetForm,
  technicalDataForm,
  apiForm,
  specificConditionsOfSaleForm,
  newForm
} from '../Form/productSubFamilies';
import { form as searchForm } from '../Form/Search/productSubFamilies';

import { productSubFamilies as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.productSubFamilies,

    id: "productSubFamily",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_MARKETING_PRODUCT_SUB_FAMILY_CREATE'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(typeof search.productFamily === 'object') {
        result["product_family_id"] = search.productFamily.id
      }
      if(search.name) {
        result["name"] = search.name;
      }
      if(search.activeOrder === true) {
        result["active_order"] = 1
      }
      else if(search.activeOrder === false) {
        result["active_order"] = 0
      }
      if(search.activeQuote === true) {
        result["active_quote"] = 1
      }
      else if(search.activeQuote === false) {
        result["active_quote"] = 0
      }
      if(search.activeTicket === true) {
        result["active_ticket"] = 1
      }
      else if(search.activeTicket === false) {
        result["active_ticket"] = 0
      }
      if(search.rentable === true) {
        result["rentable"] = 1
      }
      else if(search.rentable === false) {
        result["rentable"] = 0
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      line: {
        label: (item) => `${item["product_family"]["name"]} > ${item["name"]}`
      },
      tab: {
        headers: [
          {
            label: "Nom",
            value: "name"
          },
          {
            label: "Famille",
            value: (item) => item["product_family"]["name"]
          },
          {
            label: "Commande",
            value: (item) => item["active_order"]?"oui":"non"
          },
          {
            label: "Devis",
            value: (item) => item["active_quote"]?"oui":"non"
          },
          {
            label: "Ticket",
            value: (item) => item["active_ticket"]?"oui":"non"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.productSubFamilies,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => item.name.split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item["product_family"]["name"],
      additionalData1: (item) => item.name,
      additionalData2: (item) => ''
    },

    tabs: {
      main: {
        title: strings.productSubFamily.tab.main,
        type: 'form',
        form: form,

        apiGet: getFromId,
        apiEdit: editFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_MARKETING_PRODUCT_SUB_FAMILY_EDIT'],
      },
      configuration: {
        title: strings.productSubFamily.tab.configuration,
        type: 'form',
        form: configurationForm,

        apiGet: getConfigurationFromId,
        onUpdateItem: (data) => {},

        role: ['ROLE_MARKETING_PRODUCT_SUB_FAMILY_CONFIGURATION'],
        editable: false,
      },
      configurationEdit: {
        title: strings.productSubFamily.tab.configuration,
        type: 'form',
        form: configurationEditForm,

        apiGet: getConfigurationEditFromId,
        apiEdit: editConfigurationFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'configuration'
        },

        role: ['ROLE_MARKETING_PRODUCT_SUB_FAMILY_CONFIGURATION_EDIT'],
        editable: true,
      },
      accounting: {
        title: strings.productSubFamily.tab.accounting,
        type: 'form',
        form: accountingForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'accounting'
        },

        apiGet: getAccountingFromId,
        apiEdit: editAccountingFromId,

        role: ['ROLE_ACCOUNTING_PRODUCT_FAMILY_ACCOUNTING'],
        editable: ['ROLE_ACCOUNTING_PRODUCT_FAMILY_ACCOUNTING'],
      },
      datasheet: {
        title: strings.productSubFamily.tab.datasheet,
        type: 'form',
        form: datasheetForm,

        apiGet: getDatasheetFromId,
        apiEdit: editDatasheetFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'datasheet'
        },

        role: ['ROLE_TECHNICAL_PRODUCT_SUB_FAMILY_DATASHEET'],
        editable: true,
      },
      technicalData: {
        title: strings.productSubFamily.tab.technicalData,
        type: 'form',
        form: technicalDataForm,

        apiGet: getTechnicalDataFromId,
        apiEdit: editTechnicalDataFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'technicalData'
        },

        role: ['ROLE_TECHNICAL_PRODUCT_SUB_FAMILY_TECHNICAL_DATA'],
        editable: true
      },
      api: {
        title: strings.productSubFamily.tab.api,
        type: 'form',
        form: apiForm,

        apiGet: getAPIFromId,
        apiEdit: editAPIFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'api'
        },

        role: ['ROLE_ADMIN'],
        editable: true
      },
      specificConditionsOfSale: {
        title: strings.productSubFamily.tab.specificConditionsOfSale,
        icon: 'pen',
        type: 'form',
        form: specificConditionsOfSaleForm,

        apiGet: getSpecificConditionsOfSaleFromId,
        apiEdit: editSpecificConditionsOfSaleFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'specificConditionsOfSale'
        },

        role: ['ROLE_MARKETING_PRODUCT_SUB_FAMILY_SPECIFIC_CONDITIONS_OF_SALE_EDIT'],
        editable: true
      }
    }
  },
  new: {
    pageTitle: strings.productSubFamily.new.title,

    api: newItem,

    type: 'process',
    processId: 'newProductSubFamily',

    steps: [
      {
        id: "_general_data",
        label: strings.productSubFamily.new.steps.generalData,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['name', 'active_order', 'active_quote', 'active_ticket', 'order_breaking', 'service_tracking', 'service_breaking', 'rentable', 'available_as_ticket_origin', 'amendment_place_type', 'product_family'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_datasheet",
        label: strings.productSubFamily.new.steps.datasheet,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['datasheets'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_technical_data",
        label: strings.productSubFamily.new.steps.technicalData,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['technical_datas'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_specific_conditions_of_sale",
        label: strings.productSubFamily.new.steps.specificConditionsOfSaleForm,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['specific_conditions_of_sale'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
    ],

    onSubmit: (item) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
