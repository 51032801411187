import React from 'react';

import strings from '../../Localization/Localization';

import { processTimeframe } from './common';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getValidationFromId,
  // editFromId
  editValidationFromId
} from '../../API/CommercialBonus';
import {
  form,
  validationForm
} from '../Form/commercialBonuses';
import { form as searchForm } from '../Form/Search/commercialBonuses';

import {
  commercialBonus as routes,
  quotes as quotesRoutes
} from '../Route/routes';


export const conf = {
  prefix: "commercialBonus",
  index: {
    pageTitle: strings.common.homepage.commercialBonus,

    id: "commercialBonus",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.bonusType || search.bonusType === 0) {
        result["bonus_type"] = search.bonusType;
      }
      if(search.flatBonusValue) {
        result["flat_bonus_value"] = search.flatBonusValue;
      }
      if(search.user) {
        result["user_id"] = search.user.id;
      }
      if(search.createdAtTimeframe) {
        let createdAtTimeframe = processTimeframe(search.createdAtTimeframe);

        result["created_at_min"] = createdAtTimeframe.min;
        result["created_at_max"] = createdAtTimeframe.max;
      }
      if(search.receivedAtPrevisionTimeframe) {
        let receivedAtPrevisionTimeframe = processTimeframe(search.receivedAtPrevisionTimeframe);

        result["received_at_prevision_min"] = receivedAtPrevisionTimeframe.min;
        result["received_at_prevision_max"] = receivedAtPrevisionTimeframe.max;
      }
      if(search.receivedAtTimeframe) {
        let receivedAtTimeframe = processTimeframe(search.receivedAtTimeframe);

        result["received_at_min"] = receivedAtTimeframe.min;
        result["received_at_max"] = receivedAtTimeframe.max;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            id: "validated",
            label: <>&nbsp;&nbsp;<i className="icon-flag-1"/>&nbsp;&nbsp;</>,
            value: item => {
              if(item["validated"] === true) {
                return (
                  <div className="item-index-status item-index-status-validated-icon"><i className="icon-checkmark"/></div>
                );
              }
              else if(item["validated"] === false) {
                return (
                  <div className="item-index-status item-index-status-danger-icon"><i className="icon-cross"/></div>
                );
              }
              else { //null
                return (
                  <div className="item-index-status item-index-status-info-icon"><i className="icon-flag-1"/></div>
                );
              }
            }
          },
          {
            id: "bonusType",
            label: "Type",
            value: item => {
              switch (item['bonus_type']) {
                case 0:
                  return strings.form.commercialBonus.bonusType.options.quotePunctual;
                case 1:
                  return strings.form.commercialBonus.bonusType.options.quoteAnnual;
                case 2:
                  return strings.form.commercialBonus.bonusType.options.serviceRecurrentNoEngagement;
                default:
                  return item['bonus_type'];
              }
            }
          },
          {
            id: "commercial",
            label: "Commercial",
            value: item => item["user"]["displayname"],
          },
          {
            id: "origin",
            label: "Origin",
            href: item => item && item["origins"] && item["origins"][0] && quotesRoutes.routes.show.createPath(item["origins"][0]["id"]),
            value: item => item && item["origins"] && item["origins"][0] && item["origins"][0]["number"],
          },
          {
            id: "totalTfBase",
            label: "Base de calcul",
            value: "total_tf_base",
            type: "price"
          },
          {
            id: "flatBonusValue",
            label: "Montant",
            value: "flat_bonus_value",
            type: "price"
          },
        ]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.commercialBonus,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "commercialBonus",

    apiGet: getIdentificationFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.commercialBonus.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        apiGet: getFromId,

        role: ['ROLE_COMMERCIAL_BONUS'],
      },
      validation: {
        title: strings.commercialBonus.tab.validation,
        type: 'form',
        form: validationForm,

        onUpdateItem: (data) => {
          console.log(data);
        },
        onSubmit: (entity) => {},

        apiGet: getValidationFromId,
        apiEdit: editValidationFromId,

        role: ['ROLE_COMMERCIAL_BONUS_MANAGER'],
        editable: ['ROLE_COMMERCIAL_BONUS_MANAGER']
      }
    }
  },
}
