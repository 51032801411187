import React from 'react';

import { Container } from 'react-bootstrap';

import { connect } from 'react-redux';

/**
 * Sign
 *
 * This component managed yousign signature
 */
class Sign extends React.Component {

  static defaultProps = {
    procedure: null,
    memberId: null,
  }

  displaySign() {
    console.log('test');
    if(this.props.memberId !== null) {
      let member = this.props.procedure.members[this.props.memberId];
      if(member) {
        return (
          <iframe title="Yousign" style={{width: "100%", height: "800px"}} src={`${process.env.REACT_APP_YOUSIGN_V2_IFRAME}/procedure/sign?members=${member["uuid"]}`}></iframe>
        )
      }
    }
    if(this.props.memberId === null && this.props.procedure && this.props.procedure.members && this.props.procedure.members.some((member) => member["user"]["id"] === this.props.credentials.id)) {
      let member = this.props.procedure.members.find((member) => member["user"]["id"] === this.props.credentials.id);
      if(member) {
        return (
          <iframe title="Yousign" style={{width: "100%", height: "800px"}} src={`${process.env.REACT_APP_YOUSIGN_V2_IFRAME}/procedure/sign?members=${member["uuid"]}`}></iframe>
        )
      }
    }
    // return <Redirect to="/homepage" />
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Container className="d-flex flex-column align-items-center">
        {this.displaySign()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(Sign);
