import React from 'react';

import { Redirect } from "react-router-dom";

import { Container, Button } from 'react-bootstrap';
import '../../../css/tabs.css';

import MyForm from '../../Form/MyForm';
import { SubmitPositions } from '../../../Form/Constant';
import { withModalHandler } from '../../../HOC/ModalHandler';

import { connect } from 'react-redux';
import {
  addLineToFlashbag,
  FLASHBAG_TYPE_PRIMARY,
} from '../../../Store/Action/flashbag';

import SubFooter from '../../Layout/SubFooter';

import Loading from '../../Layout/Loading';

/**
 * MyFormTab
 *
 * This component managed a entity tab with a form
 */
class MyFormTab extends React.Component {

  static defaultProps = {
    onUpdateItem: (data) => {},
    onSubmit: (entity) => {},
  }

  constructor(props) {
    super(props);

    //state of the component, including :
    // - preLoading : if the data is actually preLoading
    // - item : the item
    this.state = {
      preLoading: true,
      preLoadingError: false,
      postLoading: false,

      redirection: false,
      path: null,

      item: null,

      disabled: true
    }

    this.getFromId = this.props.modalHandler.addVerificationWithCallback(props.apiGet, this.updateItem, this.updateItemFailure);
    this.editFromId = this.props.modalHandler.addVerificationWithCallback(props.apiEdit, this.postEdit, this.postEditFailure);
  }

  /**
   * Once the component is mounted, we can query for the information of the item
   * needed to be displayed in the component
   */
  componentDidMount() {
    this.getFromId(this.props.entityId);
  }

  /**
   * callback that collect the data from the fetch call and update the item
   *
   * @param data data from the fetch call
   */
  updateItem = (data) => {
    this.props.onUpdateItem(data);
    this.setState({
      preLoading: false,
      preLoadingError: false,
      item: data.item,
    })
  }

  updateItemFailure = (msg) => {
    console.log('test failure');
    this.setState({
      preLoading: false,
      preLoadingError: true
    })
  }

  /**
   * callback after editing the data, changes the tab
   *
   * @param data data from the edit call
   */
  postEdit = (data) => {
    this.setState({
      postLoading: false
    }, () => {
      //TODO check result in data
      this.props.dispatch(addLineToFlashbag("L'élément a bien été sauvegardé", FLASHBAG_TYPE_PRIMARY));

      if(this.props.postEdit !== null) {
        let postEdit = null;
        if(typeof this.props.postEdit === 'function') {
          // this.props.postEdit(data);
          postEdit = this.props.postEdit(data);
        }
        else if(typeof this.props.postEdit === 'object') {
          postEdit = this.props.postEdit;
        }

        if(postEdit !== null) {
          switch (postEdit.type) {
            case 'tab':
              this.props.changeTab(postEdit.tabId);
              break;
            case 'redirection':
              this.setState({
                redirection: true,
                path: postEdit.path
              });
              break;
            default:
          }
        }
      }
    })
  }

  postEditFailure = (msg) => {
    this.setState({
      postLoading: false
    });
  }

  /**
   * callback used by the form to change the item
   *
   * @param fieldKey the fieldkey to change change in the form
   * @param value the new value of the form
   */
  onChangeItem = (fieldKey, value) => {
    let item = Object.assign({}, this.state.item);
    item[fieldKey] = value;
    this.setState({
      item: item,
    });
  }

  onClickExtraButton = () => {
    this.props.onClickExtraButton();
    //should be useless if this.props.onClickExtraButton reload the page as expected
    this.setState({disabled: true});
  }

  /**
   * callback used by the form on submit
   */
  onSubmit = (item) => {
    this.setState({
      postLoading: true
    }, () => {
      this.props.onSubmit(item);
      this.editFromId(item.id, item);
    })
  }

  displayLoading() {
    if(this.state.preLoading || this.state.postLoading) {
      return (
        <Loading/>
      )
    }
  }

  displayRedirect() {
    if(this.state.redirection === true) {
      return (
        <Redirect to={this.state.path} />
      )
    }
  }

  displayForm() {
    if(!this.state.preLoading && !this.state.preLoadingError) {
      return (
        <Container className="d-flex flex-column align-items-center">
          <MyForm
            formKey={this.props.form.formKey}
            fields={this.props.form.fields}
            extraValues={this.props.form.extraValues}
            disabled={!this.props.editable || this.state.disabled}
            values={this.state.item}
            onChangeField={this.onChangeItem}
            displayExtraButton={true}
            onClickExtraButton={this.props.onClickExtraButton}
            onSubmit={this.onSubmit}
            submitPosition={SubmitPositions.SUB_FOOTER}
          />
        </Container>
      )
    }
  }

  displayEditable() {
    if(this.props.editable && this.state.disabled) {
      return (
        <SubFooter>
          <Button variant="my-information" className="btn-sub-footer"  onClick={() => this.setState({disabled: false})}><i className="icon-pen"/>&nbsp;Editer</Button>
        </SubFooter>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLoading()}
        {this.displayRedirect()}
        {this.displayForm()}
        {this.displayEditable()}
      </>
    );
  }
}

export default connect()(withModalHandler(MyFormTab));
