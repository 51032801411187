import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of documents based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('documents', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single document based on his ID
 *
 * @param id the of the document requested
 */
export function getFromId(id) {
  return baseGetFromId('documents', id);
}

/**
 * Get a single document based on his ID
 *
 * @param id the of the document requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('documents', id, 'identification');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single document based on his ID
 *
 * @param id the of the document requested
 * @param data the new data of the document
 */
export function editFromId(id, data) {
  return baseEditFromId('documents', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new document
 *
 * @param data the new data of the document
 */
export function newItem(data) {
  return baseNewItem('documents', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch documents to serve in a dropdown form choice
 *
 * @param search filter for the documents 
 */
export function fetchDocuments(search) {
  return baseIndex('documents', 1, 0, search);
}
