const productSubFamily = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Ce nom ne doit pas être vide."
    },
  },
  activeOrder: {
    label: "Activer pour les commandes ?",
  },
  activeQuote: {
    label: "Activer pour les devis ?",
  },
  activeTicket: {
    label: "Activer pour les tickets ?",
  },
  orderBreaking: {
    label: "Activer la séparation en commande ?",
  },
  serviceTracking: {
    label: "Activer le tracking de service ?",
  },
  serviceBreaking: {
    label: "Activer la séparation des services ?",
  },
  rentable: {
    label: "Activer la location ?",
  },
  displayMainIndex: {
    label: "Visible dans l'index produit ?",
  },
  availableAsTicketOrigin: {
    label: "Disponible en tant qu'origine de ticket ?"
  },
  amendmentPlaceType: {
    label: "Avenant de site",
    options: {
      contract: "Avenant au contrat",
      service: "Avenant au service"
    }
  },
  productFamily: {
    label: "Famille de produit",
    invalid: {
      assertEmpty: "La famille de produit ne peut pas être vide."
    },
  },
  datasheet: {
    label: "Datasheet",
    allowMoreField: {
      addLabel: "Ajouter une donnée",
      removeLabel: "Enlever une donnée",
      removingLabel: "Supprimer"
    }
  },
  technicalData: {
    label: "Données techniques",
    allowMoreField: {
      addLabel: "Ajouter une donnée",
      removeLabel: "Enlever une donnée",
      removingLabel: "Supprimer"
    }
  },
  phonePackage: {
    label: "Support Forfait téléphonique",
  },
  apiCdrAlphalink: {
    label: "Support API CDR Alphalink",
  },
  apiCdrFlex: {
    label: "Support API CDR Flex",
  },
  accountAnalytic: {
    label: "Compte analytique",
  },
  specificConditionsOfSale: {
    label: "Conditions particulières de vente"
  }
};

export default productSubFamily;
