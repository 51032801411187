import strings from '../../Localization/Localization';

import { fetchFamilies } from '../../API/ProductFamilies';

import { form as _datasheetForm } from './datasheet';
import { form as _technicalDataForm } from './technicalData';

import {
  productFamilies as productFamiliesRoutes,
  documents as documentsRoutes
} from '../Route/routes';

import {
  fetchAccountAnalytics
} from '../../API/AccountAnalytics';

import {
  fetchDocuments
} from '../../API/Documents';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.productSubFamily.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.productSubFamily.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let activeOrder = {
  fieldKey: "active_order",
  type: "switch",
  label: strings.form.productSubFamily.activeOrder.label,
  defaultValue: null,
  validators: []
}

let activeQuote = {
  fieldKey: "active_quote",
  type: "switch",
  label: strings.form.productSubFamily.activeQuote.label,
  defaultValue: null,
  validators: []
}

let activeTicket = {
  fieldKey: "active_ticket",
  type: "switch",
  label: strings.form.productSubFamily.activeTicket.label,
  defaultValue: null,
  validators: []
}

let orderBreaking = {
  fieldKey: "order_breaking",
  type: "triswitch",
  label: strings.form.productSubFamily.orderBreaking.label,
  defaultValue: null,
  validators: []
}

let serviceTracking = {
  fieldKey: "service_tracking",
  type: "triswitch",
  label: strings.form.productSubFamily.serviceTracking.label,
  defaultValue: null,
  validators: []
}

let serviceBreaking = {
  fieldKey: "service_breaking",
  type: "triswitch",
  label: strings.form.productSubFamily.serviceBreaking.label,
  defaultValue: null,
  validators: []
}

let rentable = {
  fieldKey: "rentable",
  type: "triswitch",
  label: strings.form.productSubFamily.rentable.label,
  defaultValue: null,
  validators: []
}

let displayMainIndex = {
  fieldKey: "display_main_index",
  type: "triswitch",
  label: strings.form.productSubFamily.displayMainIndex.label,
  defaultValue: null,
  validators: []
}

let availableAsTicketOrigin = {
  fieldKey: "available_as_ticket_origin",
  type: "switch",
  label: strings.form.productSubFamily.availableAsTicketOrigin.label,
  defaultValue: null,
  validators: [],
}

let amendmentPlaceType = {
  fieldKey: "amendment_place_type",
  type: "dropdown",
  label: strings.form.productSubFamily.amendmentPlaceType.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 'c',
      label: strings.form.productSubFamily.amendmentPlaceType.options.contract
    },
    {
      value: 's',
      label: strings.form.productSubFamily.amendmentPlaceType.options.service
    },
  ],
}

let productFamily = {
  fieldKey: "product_family",
  label: strings.form.productSubFamily.productFamily.label,
  type: "dropdownEntity",

  disabledLink: (value) => value?productFamiliesRoutes.routes.show.createPath(value["id"]):'#',

  validators: [
    {
      id: 'checkEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.productSubFamily.productFamily.invalid.checkEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  loader: {
    id: "productFamilies",
    loader: fetchFamilies,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let datasheet = {
  fieldKey: "datasheets",
  type: "array",
  label: strings.form.productSubFamily.datasheet.label,

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.productSubFamily.datasheet.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.productSubFamily.datasheet.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.productSubFamily.datasheet.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    subForm: _datasheetForm,

    validators: [],
  },

  validators: []
}

let technicalData = {
  fieldKey: "technical_datas",
  type: "array",
  label: strings.form.productSubFamily.technicalData.label,

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.productSubFamily.technicalData.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.productSubFamily.technicalData.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.productSubFamily.technicalData.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    subForm: _technicalDataForm,

    validators: [],
  },

  validators: []
}

let phonePackage = {
  fieldKey: "phone_package",
  type: "switch",
  label: strings.form.productSubFamily.phonePackage.label,
  defaultValue: null,
  validators: []
}

let apiCdrAlphalink = {
  fieldKey: "api_cdr_alphalink",
  type: "switch",
  label: strings.form.productSubFamily.apiCdrAlphalink.label,
  defaultValue: null,
  validators: []
}

let apiCdrFlex = {
  fieldKey: "api_cdr_flex",
  type: "switch",
  label: strings.form.productSubFamily.apiCdrFlex.label,
  defaultValue: null,
  validators: []
}

let accountAnalytic = {
  fieldKey: "account_analytic",
  label: strings.form.productSubFamily.accountAnalytic.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "accountAnalytic",
    loader: fetchAccountAnalytics,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["number"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["number"],
  },
}

let specificConditionsOfSale = {
  fieldKey: "specific_conditions_of_sale",
  label: strings.form.productSubFamily.specificConditionsOfSale.label,
  type: "dropdownEntity",

  disabledLink: (value) => value?documentsRoutes.routes.show.createPath(value["id"]):'#',

  validators: [],

  showEmpty: true,
  loader: {
    id: "specificConditionsOfSale",
    loader: fetchDocuments,
    loaderRequire: () => true,
    loaderArguments: () => {
      return [{
        doc_type: 'stc'
      }]
    },
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const newForm = {
  formKey: "productSubFamily",
  fields: [
    name,
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    availableAsTicketOrigin,
    amendmentPlaceType,
    productFamily,
    datasheet,
    technicalData,
    specificConditionsOfSale
  ]
}

export const configurationForm = {
  formKey: "productSubFamily",
  fields: [
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    displayMainIndex,
    availableAsTicketOrigin,
    amendmentPlaceType
  ]
}

export const configurationEditForm = {
  formKey: "productSubFamily",
  fields: [
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    displayMainIndex,
    availableAsTicketOrigin,
    amendmentPlaceType
  ]
}

export const datasheetForm = {
  formKey: "productSubFamily",
  fields: [
    datasheet
  ]
}

export const technicalDataForm = {
  formKey: "productSubFamily",
  fields: [
    technicalData
  ]
}

export const apiForm = {
  formKey: "productSubFamily",
  fields: [
    phonePackage,
    apiCdrAlphalink,
    apiCdrFlex
  ]
}

export const accountingForm = {
  formKey: "productSubFamily",
  fields: [
    accountAnalytic
  ]
}


export const specificConditionsOfSaleForm = {
  formKey: "productSubFamily",
  fields: [
    specificConditionsOfSale
  ]
}

export const form = {
  formKey: "productSubFamily",
  fields: [
    name,
    productFamily
  ]
}
