const _document = {
  name: {
    label: "Nom",
  },
  docType: {
    label: "Type",
    options: {
      generalTermsAndConditionsOfSale: "Conditions générales de vente",
      specificTermsAndConditionsOfSale: "Conditions particulières de vente"
    }
  },
  file: {
    label: "Fichier",
  },
  private: {
    label: "Le document est-il privé?",
  },
  applicableFrom: {
    label: "Appliqué à partir de",
  },
  applicableUntil: {
    label: "Appliqué jusque",
  },
};

export default _document;
