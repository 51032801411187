import React from 'react';
import BasicFormField from './BasicFormField';

import '../../../css/form.css';

import LengthMinAssert from '../../../Form/Assert/LengthMinAssert';
import LengthMaxAssert from '../../../Form/Assert/LengthMaxAssert';

import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableBody from '../../Table/TableBody';
import TableTextHeader from '../../Table/Header/TableTextHeader';
import TableRow from '../../Table/Body/TableRow';

import RawCell from './TableCell/RawCell';
import StringCell from './TableCell/StringCell';
import IntegerCell from './TableCell/IntegerCell';
import FloatCell from './TableCell/FloatCell';
import PriceCell from './TableCell/PriceCell';
import DropdownCell from './TableCell/DropdownCell';
import SwitchCell from './TableCell/SwitchCell';

/**
 * FormFieldText
 *
 * This class handles HTML form field.
 *
 * The particular field is for text.
 *
 * Specific configuration attribute : none
 */
class FormFieldTable extends BasicFormField {

  constructor(props) {
    super(props);

    this.subFieldRefs = {};
  }


  /**
   * @inheritdoc
   */
  needValidation() {
    return super.needValidation() || Object.values(this.subFieldRefs).some((item) => item.current.needValidation());
  }


  /**
   * @inheritdoc
   */
  isValid = () => {
    return Object.values(this.state.valid).every((value) => value === true)?true:false
      && Object.values(this.subFieldRefs).filter((item) => item.current !== null).every((item) => item.current.isValid());
  }

  /**
   * @inheritdoc
   */
  isInvalid = () => {
    return Object.values(this.state.valid).some((value) => value === false)?true:false
     || Object.values(this.subFieldRefs).filter((item) => item.current !== null).some((item) => item.current.isInvalid());
  }

  /**
   * Send new value to parent element
   *
   * @param key the key of the subfield
   * @param value the new value of the subfield
   */
  onChange = (arrayKey, fieldKey, value) => {
    let newValue = [...this.getValue()];
    if(this.props.table.valuesToRows !== undefined && this.props.table.valuesToRows !== null) {
      if(this.props.table.updateValuesFromRows !== undefined && this.props.table.updateValuesFromRows !== null) {
        newValue.forEach((item, i) => {
          newValue[i] = Object.assign({}, item);
        });
        this.props.table.updateValuesFromRows(newValue, arrayKey, fieldKey, value);
      }
    }
    else {
      newValue.forEach((item, i) => {
        if(i === arrayKey) {
          newValue[i] = Object.assign({}, item);
          newValue[i][fieldKey] = value;
        }
      });
    }
    super.onChange(newValue);
  }

  getSpecificAsserts() {
    return {
      lengthMin: LengthMinAssert,
      lengthMax: LengthMaxAssert,
    }
  }

  getExtraValidation() {
    return Object.values(this.subFieldRefs).filter((item) => item.current.needValidation()).map((item) => item.current);
  }

  displayHeaders() {
    return (
      <TableHeader>
        {this.props.table.headers.map((header, i) => {
          let props = {
            colSpan: header.headerColSpan,
            itemColSpan: header.itemColSpan,
            type: header.type,
            value: header.value,
            label: header.label,
            href: header.href,
            additionalConfiguration: {
              editable: header.editable,
              onChange: this.onChange
            }
          }
          switch (header.type) {
            case 'dropdown':
              props.additionalConfiguration.fieldList = header.itemFieldList
              break;
            default:
          }
          return (
            <TableTextHeader
              key={i}
              {...props}/>
          )
        })}
      </TableHeader>
    )
  }

  displayBody() {
    let rows = null;
    if(this.props.table.valuesToRows !== undefined && this.props.table.valuesToRows !== null) {
      rows = this.props.table.valuesToRows(this.props.value);
    }
    else {
      rows = this.props.value;
    }
    if(rows === undefined || rows === null || rows.length === 0) {
      return this.displayEmptyBody();
    }
    else {
      return (
        <TableBody>
          {rows.map((value, i) => {
              return (
                <TableRow key={i} data={value}/>
              )
          })}
        </TableBody>
      )
    }
  }

  displayEmptyBody() {
    return (
      <tfoot>
      </tfoot>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="container-form-field">
        <Table
          className="container-form-row"
          cellCollection={{
            raw: RawCell,
            string: StringCell,
            integer: IntegerCell,
            float: FloatCell,
            price: PriceCell,
            dropdown: DropdownCell,
            switch: SwitchCell,
          }}
          additionalData={{
            values: this.props.getValues()
          }}>
          {this.displayHeaders()}
          {this.displayBody()}
        </Table>
      </div>
    );
  }
}

export default FormFieldTable;
